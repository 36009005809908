import React, { useState, useEffect } from "react";
import ProgressBar from "./progress-bar";

const Progress: React.FC = () => {
  const [totalVotes, setTotalVotes] = useState(3547291);
  const [targetVotes, setTargetVotes] = useState(5000000);

  // Example of how you might update the totalVotes state
  useEffect(() => {
    const interval = setInterval(() => {
      setTotalVotes((prevVotes) => prevVotes + Math.floor(Math.random() * 10));
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="w-100%" style={{ flex: "0 0 100%" }}>
      <ProgressBar totalVotes={totalVotes} targetVotes={targetVotes} />
    </div>
  );
};

export default Progress;
