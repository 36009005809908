//@ts-nocheck
import React, { useContext, useEffect, useRef, useState } from "react";
import { render } from "react-dom";

// Import react-circular-progressbar module and styles
import {
    CircularProgressbar,
    CircularProgressbarWithChildren,
    buildStyles
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import giftIcon from "../../assets/images/gift-icon-head.jpeg"
// Animation
import { easeQuadInOut, easeQuadIn } from "d3-ease";
import AnimatedProgressProvider from "./AnimatedProgressProvider";
import { useWindowSize } from "../../hooks/useWindowSize";
import UserContext from "Contexts/User";
import { CurrentCMPContext, CurrentCMPDispatchContext } from "Contexts/CurrentCMP";
import styled from "styled-components";
import AppContext from "Contexts/AppContext";

export const TotalCmpDiv = styled.div`
    background: red;
    color: white;
    border-radius: 50%;
    width: 13px;
    height: 13px;
    font-size: 9px;
    position: absolute;
    right: 10px;
    top: 8px;
    z-index: 1000;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
`;
export const AnimationTotalCmpDiv = styled.div`
background: red;
color: white;
border-radius:50%;
width:12px;  
height:12px;  
font-size:8px;
position:absolute;
right:-4px;
line-height: 14px;
top:-5px;
z-index:1000;
`;


const HeaderProgressbar = ({ percentage, remainingReward }) => {
    const { width: w = 0 } = useWindowSize();
    const [progressBarValue, setProgressBarValue] = useState(0);
    const [startValue, setStartValue] = useState(false);
    const { user, userInfo } = useContext(UserContext);
    const currentCMP = useContext(CurrentCMPContext);
    const setCurrentCMP = useContext(CurrentCMPDispatchContext);
    const { animateBox, setAnimateBox, setShow } = useContext(AppContext);   
    const lastCmpValue = useRef(percentage);
    useEffect(() => {
        // setProgressBarValue(0);
        const time = setTimeout(() => {
            setProgressBarValue(percentage);
        }, [800]);
    }, [percentage]);
   
    const [calledCount, setCalledCount] = useState(0);
    let newScore = localStorage.getItem(`${user?.uid}_newScoresHeader`) || "0";    
    let newScoreCmp = localStorage.getItem(`${user?.uid}_newScores`) || "0";    
    let DBScore = localStorage.getItem(`${user?.uid}_prevScores`) || "0";   
    
    console.log(percentage,"percentageValue")
    useEffect(() => {
        setCalledCount((prev) => prev + 1)
        if (newScore == 0) {
            // if (parseFloat(DBScore) - parseFloat(newScoreCmp) < 100) {            
            //     lastCmpValue.current = percentage
            // } else {
            // }            
            lastCmpValue.current = percentage
        }
    }, [newScore, userInfo?.rewardStatistics?.total])

    



    // currentScore=localStorage.getItem('')
    return (
        <div style={{
            width: w > 979 ? "55px" : "45px", height: w > 979 ? "55px" : "45px",
        }}
        >           
            <div
            >
                <CircularProgressbarWithChildren
                    // value={(percentage) < 0 ? 0 : (percentage)}
                    value={(lastCmpValue.current) < 0 ? 0 : (lastCmpValue.current)}
                    background={true}
                    strokeWidth={8}
                    styles={buildStyles({
                        pathColor: "#6352e8",
                        pathTransition: "none",
                        strokeLinecap: "butt",
                        trailColor: ('white'),
                        backgroundColor: "white",
                    })}
                >
                    <div
                        style={{
                            width: w > 979 ? "47px" : "38px", height: w > 979 ? "47px" : "38px",
                            border: "1px solid #160133",
                            borderRadius: "50%",
                        }}
                    >
                        {
                            !animateBox ?
                                <>
                                <div className="coinIcon">
                                    {remainingReward > 0 && <TotalCmpDiv>
                                        <span>{remainingReward}</span>
                                    </TotalCmpDiv>}
                                    <img src={giftIcon} alt='' className="gift-icon" width="20px" />
                                    </div>
                                </>
                                :
                                <>
                                    
                                     <div className="gift_animation-css">
                                     <div className="coinIcon">
                                     {remainingReward > 0 &&<AnimationTotalCmpDiv>
                                        <span>{remainingReward}</span>
                                    </AnimationTotalCmpDiv>}
                                        <img src={giftIcon} alt='' className="gift-icon-css" width="30px" />
                                        </div>
                                    </div>
                                </>
                        }
                    </div>
                </CircularProgressbarWithChildren>
            </div>
        </div >
    );
};

export default HeaderProgressbar;



// //@ts-nocheck
// import React, { useContext, useEffect, useState } from "react";
// import {
//     CircularProgressbarWithChildren,
//     buildStyles,
// } from "react-circular-progressbar";
// import "react-circular-progressbar/dist/styles.css";
// import giftIcon from "../../assets/images/gift-icon-head.jpeg"
// // Animation
// import { easeQuadIn } from "d3-ease";
// import AnimatedProgressProvider from "./AnimatedProgressProvider";
// import { useWindowSize } from "../../hooks/useWindowSize";
// import UserContext from "Contexts/User";
// import {
//     CurrentCMPContext,
//     CurrentCMPDispatchContext,
// } from "Contexts/CurrentCMP";
// import AppContext from "Contexts/AppContext";
// import styled from "styled-components";



// export const TotalCmpDiv = styled.div`
//     background: red;
//     color: white;
//     border-radius: 50%;
//     width: 13px;
//     height: 13px;
//     font-size: 9px;
//     position: absolute;
//     right: 10px;
//     top: 8px;
//     z-index: 1000;
//     display: flex;
//     -webkit-box-align: center;
//     align-items: center;
//     -webkit-box-pack: center;
//     justify-content: center;
// `;
// export const AnimationTotalCmpDiv = styled.div`
// background: red;
// color: white;
// border-radius:50%;
// width:12px;  
// height:12px;  
// font-size:8px;
// position:absolute;
// right:-4px;
// line-height: 14px;
// top:-5px;
// z-index:1000;
// `;

// const HeaderProgressbar = ({ percentage, remainingReward }) => {
//     const { width: w = 0 } = useWindowSize();
//     const [startValue, setStartValue] = useState(false);
//     const { user, userInfo } = useContext(UserContext);
//     const { prevCMP, latestCMP, headerCpmStart,setHeaderCpmStart } = useContext(AppContext);
//     const { animateBox, setAnimateBox} = useContext(AppContext); 
//     const [endValue, setEndValue] = useState<Number>(
//         userInfo?.voteStatistics?.score % 100 || 0
//     );
//     const currentCMP = useContext(CurrentCMPContext);
//     const setCurrentCMP = useContext(CurrentCMPDispatchContext);

//     let newScore = localStorage.getItem(`${user?.uid}_newScoresHeader`) || "0";
//     useEffect(() => {
//         console.log(newScore,headerCpmStart,"headerCpmStart")
//         if (newScore != "0" && headerCpmStart) {
//             setHeaderCpmStart(false)
//             setTimeout(() => {
//                 let cmpVal = prevCMP.current;                
//                 if (parseFloat(newScore) > parseFloat(cmpVal)) {
//                     // remainingReward();
//                     var normalValue = 100 - (parseFloat(newScore) - parseFloat(cmpVal));
//                     var startVal = normalValue < 0 ? 0 : normalValue
//                 } else if (parseFloat(cmpVal) > parseFloat(newScore)) {
//                     console.log(newScore, cmpVal, "checkbothvalue")
//                     startVal = parseFloat(cmpVal) - parseFloat(newScore);
//                 } else if (parseFloat(cmpVal) == parseFloat(newScore)) {
//                     startVal = 0;
//                 }
//                 let endVal = startVal + parseFloat(newScore);

//                 console.log(cmpVal, newScore, "get both value ");
//                 setEndValue(parseFloat(endVal) || endValue);
//                 setStartValue(parseFloat(startVal) || 0);
//             }, [10]);
//             setTimeout(() => {
//                 localStorage.setItem(`${user?.uid}_newScoresHeader`, 0);
//                 localStorage.setItem(
//                     `${user?.id}_prevScoresHeader`,
//                     userInfo?.voteStatistics?.score % 100
//                 );                 
//                 setStartValue(false);
//                 setEndValue(parseFloat(userInfo?.voteStatistics?.score % 100));
//                 // prevCMP.current = percentage;
//                 // latestCMP.current = undefined;
//                 // setCurrentCMP(0);
//             }, [2000]);
//         }
//     }, [percentage, newScore, headerCpmStart]);

//     console.log(startValue, endValue, "startValue, endValue");
//     console.log(prevCMP.current, latestCMP.current, prevCMP.current, "latestCMP.current");
//     return (
//                 <div style={{
//                     width: w > 979 ? "55px" : "45px", height: w > 979 ? "55px" : "45px",
//                 }}>
//             <CircularProgressbarWithChildren
//                 value={100}
//                 strokeWidth={1}
//                 styles={buildStyles({
//                     pathColor: "#6352e8",
//                     pathTransition: "none",
//                     strokeLinecap: "butt",
//                     trailColor: ('white'),
//                     backgroundColor: "white",
//                 })}
//             >
//                 {startValue !== false &&
//                     localStorage.getItem(`${user?.uid}_newScoresHeader`) &&
//                     localStorage.getItem(`${user?.uid}_newScoresHeader`) != "0" ? (
//                     <AnimatedProgressProvider
//                         valueStart={parseFloat(startValue)}
//                         valueEnd={parseFloat(endValue)}
//                         duration={4}
//                         easingFunction={easeQuadIn}
//                     >
//                         {(value) => {
//                             const roundedValue = parseFloat(value.toFixed(3));
//                             console.log(startValue, endValue, value, "getboth value");
//                             return (
//                                 <>
//                                     <div>
//                 <CircularProgressbarWithChildren
//                     value={roundedValue < 0 ? 0 : roundedValue}
//                     background={true}
//                     strokeWidth={8}
//                     styles={buildStyles({
//                         pathColor: "#6352e8",
//                         pathTransition: "none",
//                         strokeLinecap: "butt",
//                         trailColor: ('white'),
//                         backgroundColor: "white",
//                     })}
//                 >
//                     <div
//                         style={{
//                             width: w > 979 ? "47px" : "38px", height: w > 979 ? "47px" : "38px",
//                             border: "1px solid #160133",
//                             borderRadius: "50%",
//                         }}
//                     >
//                         {
//                             !animateBox ?
//                                 <>
//                                 <div className="coinIcon">
//                                     {remainingReward > 0 && <TotalCmpDiv>
//                                         <span>{remainingReward}</span>
//                                     </TotalCmpDiv>}
//                                     <img src={giftIcon} alt='' className="gift-icon" width="20px" />
//                                     </div>
//                                 </>
//                                 :
//                                 <>
                                    
//                                      <div className="gift_animation-css">
//                                      <div className="coinIcon">
//                                      {remainingReward > 0 &&<AnimationTotalCmpDiv>
//                                         <span>{remainingReward}</span>
//                                     </AnimationTotalCmpDiv>}
//                                         <img src={giftIcon} alt='' className="gift-icon-css" width="30px" />
//                                         </div>
//                                     </div>
//                                 </>
//                         }
//                     </div>
//                 </CircularProgressbarWithChildren>
//                                     </div>
//                                 </>
//                             );
//                         }}
//                     </AnimatedProgressProvider>
//                 ) : (
//                     <div className="">
//                 <CircularProgressbarWithChildren
//                                 value={
//                                     (endValue ? parseFloat(endValue) : percentage) -
//                                         localStorage.getItem(`${user?.uid}_newScoresHeader`) <
//                                         0
//                                         ? 0
//                                         : (parseFloat(endValue) ? parseFloat(endValue) : percentage) -
//                                         localStorage.getItem(`${user?.uid}_newScoresHeader`)
//                                 }
//                     background={true}
//                     strokeWidth={8}
//                     styles={buildStyles({
//                         pathColor: "#6352e8",
//                         pathTransition: "none",
//                         strokeLinecap: "butt",
//                         trailColor: ('white'),
//                         backgroundColor: "white",
//                     })}
//                 >
//                     <div
//                         style={{
//                             width: w > 979 ? "47px" : "38px", height: w > 979 ? "47px" : "38px",
//                             border: "1px solid #160133",
//                             borderRadius: "50%",
//                         }}
//                     >
//                         {
//                             !animateBox ?
//                                 <>
//                                 <div className="coinIcon">
//                                     {remainingReward > 0 && <TotalCmpDiv>
//                                         <span>{remainingReward}</span>
//                                     </TotalCmpDiv>}
//                                     <img src={giftIcon} alt='' className="gift-icon" width="20px" />
//                                     </div>
//                                 </>
//                                 :
//                                 <>
                                    
//                                      <div className="gift_animation-css">
//                                      <div className="coinIcon">
//                                      {remainingReward > 0 &&<AnimationTotalCmpDiv>
//                                         <span>{remainingReward}</span>
//                                     </AnimationTotalCmpDiv>}
//                                         <img src={giftIcon} alt='' className="gift-icon-css" width="30px" />
//                                         </div>
//                                     </div>
//                                 </>
//                         }
//                     </div>
//                 </CircularProgressbarWithChildren>
//                     </div>
//                 )}
//             </CircularProgressbarWithChildren>
//         </div>
//     );
// };

// export default HeaderProgressbar;

