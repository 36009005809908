//@ts-nocheck
import React, { useEffect, useState } from "react";
import {
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import CPLogo from "../../assets/images/CPLogo.png";
import v2eLogo from "../../assets/images/VTElogo.png";
import spLogo from "../../assets/images/sportLogo.png";
import stockLogo from "../../assets/images/stockLogo.png";
import votingLogo from "../../assets/images/votingLogo.png";

// Animation
import {
  easeQuadInOut,
  easeQuadIn,
  easeCubicInOut,
  easeQuadOut,
  easeLinear,
  easeQuad,
  easeCubic,
} from "d3-ease";
import AnimatedProgressProvider from "./AnimatedProgressProvider";

export const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });
  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return windowSize;
};



const FullcircleProgressbar = () => {
  const { width: w = 0 } = useWindowSize();
  const [key, setKey] = useState(0); // To force re-render of AnimatedProgressProvider
  // const location = useLocation();
  const pathname = window.location.pathname;
  const searchParams = new URLSearchParams(window.location.search);
  const title = searchParams.get("title");
  const logo = searchParams.get("logo");
  const textColor = searchParams.get('textColor');  

  useEffect(() => {
    const interval = setInterval(() => {
      setKey((prevKey) => prevKey + 1); // Change the key to re-render AnimatedProgressProvider
    }, 5000); // Reset every 5 seconds

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <div
      style={{
        background: "transparent",
        // width: "100%",
        alignItems: "center",
        justifyContent: "center",
        // padding: "10px",
        // height: "100vh",
        overflow: "hidden",
        display: "flex",
      }}
      // onClick={handleClick}
    >
      <div>
        <div style={{ display: "grid", placeItems: "center" }}>
          <div
            style={{
              width: w > 767 ? "98%" : "52%",
              background: "transparent",
              maxWidth: "50%",
            }}
          >
            <CircularProgressbarWithChildren
              value={100}
              strokeWidth={w > 767 ? 11 : 11}
              styles={buildStyles({
                pathColor: w > 767 ? "#160133" : "#160133",
                pathTransition: "none",
                strokeLinecap: "butt",
                trailColor: "transparent",
              })}
            >
              <AnimatedProgressProvider
                key={key}
                valueStart={0}
                valueEnd={100}
                duration={2}
                easingFunction={easeQuadOut}
              >
                {(value) => {
                  const roundedValue = parseFloat(value.toFixed(3));
                  return (
                    <div>
                      <CircularProgressbarWithChildren
                        value={roundedValue < 0 ? 0 : roundedValue}
                        strokeWidth={w > 767 ? 11 : 11}
                        styles={buildStyles({
                          pathColor: "#6352e8",
                          pathTransition: "none",
                          strokeLinecap: "butt",
                          trailColor: w > 767 ? "transparent" : "#160133",
                        })}
                      >
                        <span
                          style={{
                            color: textColor ? textColor : "#160133",
                            fontSize: w > 767 ? "14px" : "5px",
                          }}
                        >
                          {(roundedValue > 100 ? 100 : roundedValue).toFixed(2)}
                          /100
                        </span>
                        <span
                          style={{
                            color: textColor ? textColor : "var(--blue-violet)",                            
                            fontSize: w > 767 ? "16px" : "7px",
                          }}
                        >
                          CMP
                        </span>
                      </CircularProgressbarWithChildren>
                    </div>
                  );
                }}
              </AnimatedProgressProvider>
            </CircularProgressbarWithChildren>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FullcircleProgressbar;
