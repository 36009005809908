import AppContext from 'Contexts/AppContext';
import UserContext from 'Contexts/User';
import React, { useContext } from 'react'

const PaymentSuccess: React.FC<{ paymentSuccessAction: () => void, message?: string }> = ({ paymentSuccessAction, message = 'Your payment has been confirmed' }) => {
    
    // @ts-ignore
    let AllInfo = JSON.parse(localStorage.getItem("PayAmount"))
    const { getSpinData, } = useContext(AppContext);
    const {userInfo } = useContext(UserContext);



    return (
        <div className='w-100 d-flex justify-content-center'>
            <div style={{
                // backgroundColor: 'white',
                boxSizing: 'border-box',
                gridTemplateColumns: 'minmax(0, 100%)',
                width: window.screen.width < 767 ? '98%' : "32em",
                maxWidth: '100%',
                padding: '0 0 1.25em',
                border: 'none',
                borderRadius: '5px',
                background: '#fff',
                color: '#545454',
                fontFamily: 'inherit',
                fontSize: '1rem',
                display: 'grid',
                gridColumn: '2',
                gridRow: '2',
                alignSelf: 'center',
                justifySelf: 'center',
            }}>
                <div className="swal2-icon swal2-success swal2-icon-show" style={{ display: "flex" }}>
                    <div className="swal2-success-circular-line-left" style={{ backgroundColor: "rgb(255, 255, 255)" }}></div>
                    <span className="swal2-success-line-tip"></span> <span className="swal2-success-line-long"></span>
                    <div className="swal2-success-ring"></div> <div className="swal2-success-fix" style={{ backgroundColor: "rgb(255, 255, 255)" }}></div>
                    <div className="swal2-success-circular-line-right" style={{ backgroundColor: "rgb(255, 255, 255)" }}></div>
                </div>
                <div style={{
                    fontSize: '1.575em',
                    fontWeight: '600',
                    textAlign: 'center',
                    marginTop:'10px'
                }}>Payment Successful</div>
                {AllInfo[1] == "EXTRAVOTES" && <div style={{
                    fontSize: '1.125em',
                    fontWeight: 'normal',
                    lineHeight: 'normal',
                    textAlign: 'center',
                    margin: '1em 2.6em .3em',
                    padding: '0'
                }}>
                    {/* {message} */}
                    {/* You get {AllInfo[2]} Votes */}
                    Well done! Your vote purchase unlocks more opportunities for you. 🎉 Now you can vote more and earn more!
                </div>}
                {AllInfo[1] == "UPGRADE" && <div style={{
                    fontSize: '1.125em',
                    fontWeight: 'normal',
                    lineHeight: 'normal',
                    textAlign: 'center',
                    margin: '1em 2.6em .3em',
                    padding: '0'
                }}>
                    {/* {message} */}
                    {/* You get {AllInfo[2]} Votes and 500 VTE */}
                    Awesome! Your account upgrade is a big step forward. 🚀 Let's explore the perks together!
                </div>}
                <div style={{
                    fontSize: '1.125em',
                    fontWeight: 'normal',
                    lineHeight: 'normal',
                    textAlign: 'center',
                    margin: '1em 1.6em .3em',
                    padding: '0'
                }}>
                    {message}
                </div>
                
                

                <div className='d-flex justify-content-center'>
                    <button type="button"
                        style={{
                            display: 'inline-block',
                            backgroundColor: 'rgb(84, 60, 214)',
                            border: '0',
                            borderRadius: '0.25em',
                            color: 'white',
                            fontSize: '1em',
                            margin: '.3125em',
                            padding: '.625em 1.1em',
                            transition: 'box-shadow .1s',
                            boxShadow: '0 0 0 3px rgba(0,0,0,0)',
                            fontWeight: '500',
                        }}
                        onClick={() => {
                            paymentSuccessAction()
                            if (AllInfo[1] == "MEGASPIN") {
                                getSpinData(userInfo?.uid)
                            }
                        }
                        }
                    >Close</button>
                </div>
            </div>

        </div>
    )
}

export default PaymentSuccess;