import React from "react";

const Bull = ({ color = "#6352e8" }: { color?: string }) => {
  return (
    <svg
    className='svg-path'
      xmlns="http://www.w3.org/2000/svg"
      width="56.494"
      height="45.66"
      viewBox="0 0 73.326 65.606"
      stroke='currentColor'
      fill="white"
    >
      <g id="bull" transform="translate(0.66 0.651)">
        <path
          id="Path_3656"
          data-name="Path 3656"
          d="M56.643.04,56.4.263c.538.843,1.092,1.677,1.611,2.531a45.8,45.8,0,0,1,5.54,11.739,3.07,3.07,0,0,1-1.423,3.713,5.7,5.7,0,0,1-1.989.923c-3.393.582-6.795,1.141-10.213,1.543a6.136,6.136,0,0,1-2.986-.551,25.978,25.978,0,0,0-21.236-.222,9.318,9.318,0,0,1-5.031.806c-3.261-.427-6.591-.5-9.671-1.849-2.559-1.122-3.345-2.64-2.293-5.2,1.309-3.181,2.854-6.269,4.392-9.349.712-1.427,1.651-2.741,2.488-4.105L15.36,0C13.7,1.109,11.992,2.157,10.386,3.339c-3.52,2.592-6.744,5.5-8.9,9.384C-.985,17.189-.379,21.7,3.336,24.689,4.844,25.9,6,27.03,5.875,29.075a3.445,3.445,0,0,0,.254,1.131c1.038,3.422,2.837,6.237,6.611,6.9a25.237,25.237,0,0,0,5.251-.166,8.49,8.49,0,0,0,.007,1,28.118,28.118,0,0,0,.468,4.019,12.8,12.8,0,0,0,4.492,6.627c1.817,1.55,3.04,3.355,2.367,5.991a4.152,4.152,0,0,0,1,3.693,3.45,3.45,0,0,1,.682,1.189,6.013,6.013,0,0,0,3.679,4.083,15.54,15.54,0,0,0,8.931.533,6.446,6.446,0,0,0,5.5-4.888,2.5,2.5,0,0,1,.505-.9,4.585,4.585,0,0,0,.965-4.257A3.583,3.583,0,0,1,47,51.026a13.619,13.619,0,0,1,2.278-2.75,12.922,12.922,0,0,0,4.744-10.659c-.011-.32.034-.642.06-1.056.332.108.509.167.688.224,4.49,1.429,8.373-.185,10.305-4.468A18.463,18.463,0,0,0,66.3,27.748a2.819,2.819,0,0,1,1.262-2.156c4.439-3.021,5.666-7.68,3.161-12.415C67.547,7.185,62.21,3.471,56.643.04Z"
          // fill="none"
          
          strokeMiterlimit="10"
          strokeWidth="1"
        />
      </g>
    </svg>
  );
};

export default Bull;
