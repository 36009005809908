import { Form, OverlayTrigger, Tooltip } from "react-bootstrap";
import React, { useContext, useEffect } from "react";
import AppContext from "../Contexts/AppContext";
import SelectTimeframes from "./Coins/SelectTimeframes";
import { default as CPVote } from "./Coins/Vote";
import { Title } from "../Pages/SingleCoin";
import { Link, useParams } from "react-router-dom";
import UserContext from "../Contexts/User";
import RangeSilder from "./Users/RangeSilder";
import Countdown from "react-countdown";
import { VoteResultProps } from "../common/models/Vote";

export const colors = ["#6352e8", "white"];

type VoteFormProps<T> = {
  option1: T;
  option2: T;
  id: string;
  texts: {
    yourVote: string;
    selectTimeFrame: string;
    tooltip: string;
  };
  canVote: boolean;
  selectedTimeFrameArray?: any;
  selectedTimeFrame?: number;
  setSelectedTimeFrame: (n: number) => void;
  selectedOption?: number;
  setSelectedOption: (n: number) => void;
  submit: () => void;
  children?: React.ReactNode;
  disabled?: boolean;
  width?: number | string;
  cssDegree?: any;
  votePrice?: any;
  votedDetails?: any;
  hideButton?: any;
  setHideButton?: React.Dispatch<React.SetStateAction<number[]>>;
  setpopUpOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  vote: VoteResultProps,
  disableVoteButton?: boolean
};
const VoteForm = function <
  T extends {
    image: string | React.ReactNode;
    alt: string;
    title?: JSX.Element;
  }
>({
  option1,
  option2,
  id,
  texts,
  canVote,
  selectedTimeFrameArray,
  selectedTimeFrame,
  setSelectedTimeFrame,
  selectedOption,
  setSelectedOption,
  children,
  disabled,
  width,
  submit,
  cssDegree,
  votePrice,
  votedDetails,
  hideButton,
  setHideButton,
  setpopUpOpen,
  vote,
  disableVoteButton
}: VoteFormProps<T>) {
  const { timeframes, login, remainingTimer, voteRules, afterVotePopup, oneVoteOnly, setOneVoteOnly} = useContext(AppContext);
  const { user } = useContext(UserContext);
  let params = useParams();
  const [symbol1, symbol2] = (params?.id || "").split("-");
  const { userInfo } = useContext(UserContext);

  // console.log(!hideButton.includes(selectedTimeFrame),"selectedTimeFrame Now")

  // console.log(texts?.tooltip, "all Text")
  // console.log(voteRules, "selectedTimeFrame")
  return (
    <Form
      className='mt-3'
      id={id}
      noValidate={true}
      onSubmit={(e) => {
        e.preventDefault();
        const voteValue = userInfo?.rewardStatistics?.extraVote + userInfo?.voteValue
        if (voteValue > 0 &&oneVoteOnly == false) {
          setOneVoteOnly(true);
          submit();
        }
      }}
      style={{ maxWidth: '450px', margin: '0 auto' }}
    >      
      <div className="mt-4" style={{ marginLeft: symbol2 ? '' : '24px', marginRight: symbol2 ? '' : '24px' }}>
        <SelectTimeframes
          {...{
            selected: selectedTimeFrame,
            timeframes,
            selectTimeframe: (timeframe) => {
              setSelectedTimeFrame(timeframe.index);
            },
            title: texts.selectTimeFrame,
            selectedTimeFrameArray: selectedTimeFrameArray,
            cssDegree,
            votePrice,
            votedDetails,
            hideButton,
            setHideButton,
            setpopUpOpen,
            vote,
            disableVoteButton
          }}
        />

      </div>      
      <div className='mt-4 pt-2'>
        {/* @ts-ignore */}
        <div 
          className="text-center"
          style={{
            color: "#6352e9",
            fontSize:"13px"
          }}
        ><p>Who gets your vote?</p> </div>
        <div className='mb-3'>
          {/* <Title>{texts.yourVote}</Title> */}
        </div>        
        <div className="">
          <CPVote
            {...{
              selectedOption,
              setSelectedOption,              
            }}
            width={width || 266}
            // disabled={!canVote || disabled}
            disabled={!!!user}
            disabledText={texts.tooltip}
            options={[
              {
                icon:
                  typeof option1.image === "string" ? (
                    <img src={option1.image} alt={option1.alt} />
                  ) : (
                    <>
                      {/* <p>vote {option1.image} BEAR</p> */}
                      {/* @ts-ignore */}
                      {option1?.buttonText ? <p>{option1?.buttonText[0]} {option1.image} {option1?.buttonText[1]}</p> : <> Vote<p>{option1.image}</p> </>}
                    </>
                  ),
                buttonProps: {
                  children: undefined,
                },
              },
              {
                icon:
                  typeof option2.image === "string" ? (
                    <img src={option2.image} alt={option2.alt} />
                  ) : (
                    <>
                      {" "}                      
                      {/* @ts-ignore */}
                      {option2?.buttonText ? <p>{option2?.buttonText[0]} {option2.image} {option2?.buttonText[1]}</p> : <> Vote<p>{option2.image}</p> </>}
                    </>
                  ),
                buttonProps: {
                  children: undefined,
                },
              },
            ]}
          >
            {children}
          </CPVote>
        </div>
      </div >
    </Form >
  );
};

export default VoteForm;
