/** @format */

import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled, { createGlobalStyle } from "styled-components";
import Leaderboard from "../Components/Leaderboard";
import NftOneCard from "./NftOneCard";
import "./styles.css";
import {
  leaders as leaders,
  userInfo as userInfo1,
} from "../Components//Coins/testData";
import UserContext from "../Contexts/User";
import CoinsContext from "../Contexts/CoinsContext";
import AppContext from "../Contexts/AppContext";
import { setChecked } from "../common/models/User";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  setDoc,
  where,
} from "firebase/firestore";
import { db } from "../firebase";
import { functions } from "../firebase";
import { httpsCallable } from "@firebase/functions";
import firebase from "firebase/compat/app";
import { Other } from "./SingleCoin";
import { translate, useTranslation } from "../common/models/Dictionary";
import { Ratio, Tab, Tabs } from "react-bootstrap";
import { texts } from "Components/LoginComponent/texts";
import axios from "axios";
import { firestore } from "../firebase";
import Button, { Buttons } from "Components/Atoms/Button/Button";
import BuyerPopup from "Components/Profile/CardTrade/BuyerPopup";
import { confirmDelete, getUserData } from "common/utils/helper";
import { showToast } from "App";
import { ToastType } from "Contexts/Notification";
import { Modal } from "react-bootstrap";
import NFTTradeCard from "common/NFTCard/NFTTradeCard";
import NFTTradeCoin from "common/NFTCard/NFTTradeCoin";
import CardSalePopup from "Components/Profile/CardTrade/CardSalePopup";
import ShareModal from "Components/shareModal";
import { TradeButton, TradeText } from "Components/Users/UserCard";
import Swal from "sweetalert2";

const CenterItem = styled.div`
  background-color: #f2f2f2;
  overflow-x: hidden;
  width: 100%;

  // & p {
  //   font-weight: 100;
  //   text-align: center;
  //   padding: 20px;
  //   font-size: 20px;
  //   color: #160133;
  //   text-transform: uppercase;
  // }
  // & strong {
  //   // font-weight: 400;
  //   text-align: center;
  //   // padding: 30px;
  //   font-size: 20px;
  //   color: #160133;

  // }
`;
const InfoDiv = styled.div`
    font-size:10px;    
`;
const TradeButtons = styled.button`
  color: white;
  font-size: 12px;
  padding: 10px 10px;
  border: none;
  border-radius: 5px;
`;
const SummerCard = styled.div`
  width: ${window.screen.width > 767 ? "730px" : "100%"};
  margin: auto;
  display: flex;
  justify-content: center;
`;

const SingalCard = () => {
  // const [cards, setCards] = useState(

  //   {
  //     id: 1,
  //     cardType: "COMMON",
  //     cardNo: "CP244",
  //     cardHeader: "INVESTOR",
  //     type: "SUMMER",
  //   },

  // );

  const [CardValue, setCardValue] = useState([]);
  const [backCards, setBackCards] = useState<any>([]);
  const [cardModalShow, setCardModalShow] = useState<any>(false);
  const [coinModelShow, setCoinModelShow] = useState<any>(false);
  const [approvedCard, setApprovedCard] = useState<any>();
  const { user, userInfo } = useContext(UserContext);
  const { leaders } = useContext(CoinsContext);
  const {
    albumOpen,
    setAlbumOpen,
    userTypes,
    setSeletcardHolderType,
    seletcardHolderType,
  } = useContext(AppContext);
  const [chosen, setChosen] = useState<string | undefined>();
  const handleCardClose = () => setCardModalShow(false);
  const handleCoinClose = () => setCoinModelShow(false);

  console.log("approvedCard", approvedCard);
  console.log("coinModelShow approvedCard", coinModelShow, approvedCard);

  const BackSideCard = (value: string | number) => {
    // @ts-ignore
    if (backCards.includes(value)) {
      let allBackCard = [...backCards];
      allBackCard.splice(backCards.indexOf(value), 1);
      setBackCards(allBackCard);
    } else {
      setBackCards([...backCards, value]);
    }
  };

  const [cardsDetails, setCardsDetails] = useState<any>();
  const [nftAlbumData, setNftAlbumData] = useState<any>();
  const [followersDetails, setFollowersDetails] = useState<any>();
  const [FollowerListByUserId, setFollowerListByUserd] = useState<any>();
  const [followersShow, setFollowersShow] = useState<any>([]);
  const [isLoading, setIsLoading] = useState<any>(false);
  const [isLoadingText, setIsLoadingText] = useState<any>(false);
  const [singalCardData, setSingalCardData] = useState<any>([]);
  const [winnerCard, setWinnerCard] = useState<any>();
  const [buyerCards, setBuyerCard] = useState<any>([]);
  const [allBuyerList, setAllBuyerList] = useState<any>([]);
  const [allSellerList, setAllSellerList] = useState<any>([]);
  const [activeTab, setActiveTab] = useState(0);
  const [openModal, setOpenModal] = useState<any>("");
  const [openSellerModal, setOpenSellerModal] = useState<any>("");
  const [openPopupType, setOpenPopupType] = useState<any>("COIN");
  const [buyerRequrestData, setBuyerRequrestData] = useState<any>({});
  const [SellerRequrestData, setSellerRequrestData] = useState<any>({});
  const [tradeDetails, setTradeDetails] = useState<any>([]);
  const [openTradCard, setOpenTradeCard] = useState<any>();
  const [shareModalShow, setShareModalShow] = React.useState(false);
  const [allRequestsList, setAllRequestsList] = useState<any>([]); 
  const [commenSerialNo, setCommenSerialNo] = useState<any>([]); 
  const [requestsPopup, setRequestsPopup] = React.useState(false);
  // const { singalCardData,setSingalCardData} = useContext(AppContext);
  const url = `${document.location.protocol}//${
    document.location.host
  }/profile/CollectiblesAlbum?collectionName=${approvedCard?.cardDetails?.winData?.firstRewardCardCollection?.replace(
    " ",
    "-"
  )}&cardName=${approvedCard?.cardDetails?.winData?.firstRewardCard?.replace(
    " ",
    "-"
  )}`;
  const shareText =
    "I won this unique card! Join the Parliament and win with me.";

  let params = useParams();
  const translate = useTranslation();
  const { type, id } = params;
  const navigate = useNavigate();

  const handleAllRequestsShow = () => setRequestsPopup(true);
  const handleAllRequestsClose = () => {
    setRequestsPopup(false);
  };

  const getList = httpsCallable(functions, `cardHolderListing`);
  const getFollwersList = async (id: any) => {
    setIsLoadingText(true);
    const initiated: any = (await getListByUserId()) || [];

    await getList({ cardId: id })
      .then(async (list) => {
        // @ts-ignore
        const FollowerList = list?.data?.map((items: any) => {
          let arrayData: any = initiated.filter(
            (e: any) =>
              e.sellerUserId == items.uid &&
              e.cardId === id &&
              !["REJECTED", "APPROVED", "EXPIRED"].includes(e.status)
          );
          return {
            leaders: items?.leader?.length || 0,
            displayName: items?.displayName,
            userId: items?.uid,
            avatar: items?.avatar,
            status: items?.status?.name,
            phone: items?.phone,
            country: items?.country,
            score: items?.voteStatistics?.score,
            totalVote: items?.voteStatistics?.total,
            successful: items?.voteStatistics?.successful,
            subscribers: items?.subscribers.length || 0,
            pct:
              items?.voteStatistics?.successful / items?.voteStatistics?.total,
            firstName: items?.firstName,
            email: items?.email,
            lastName: items?.lastName,
            isUserUpgraded: items?.isUserUpgraded,
            userName: items?.userName,
            tradeCardData: arrayData[0],
          };
        });
        setFollowersDetails(FollowerList);
        setIsLoadingText(false);
      })
      .catch((error) => {
        console.log(`error: ${JSON.stringify(error)}`);
        setIsLoadingText(false);
      });
  };

  const getListByUserId = async () => {
    // setFollowerListByUserd
    let responce: any = await axios
      .get(`cardTrading/getInitiatedTrade/${user?.uid}`)
      .then((res) => {
        if (res.status === 200) {
          setBuyerCard(res?.data?.result);
          return res?.data?.result;
        }
      })
      .catch((error) => {
        return [];
      });

    return responce;
  };

  // /cardTrading/getSellerTradeToUser
  const getSellerTradeToUser = async () => {
    let response: any = await axios
      .get(`/cardTrading/getSellerTradeToUser`)
      .then((res: any) => {
        if (res.status === 200) {
          return res?.data?.result;
        }
      })
      .catch((error: any) => {
        console.log("error", error);
        return [];
      });
    return response;
  };

  const getNftCard = async () => {
    try {
      const q = query(
        collection(db, "nft_gallery"),
        where("collectionName", "==", type)
      );
      const querySnapshot = await getDocs(q);

      const allcollection = querySnapshot.docs.map((doc) => doc.data());

      const collectionType = allcollection.map(
        (allCard) => allCard?.setDetails
      );

      return collectionType;
    } catch (error) {
      console.error("Error getting nft cards:", error);
      return null;
    }
  };

  const getCheckBuyerList = async () => {
    try {
      axios
        .get(`/cardTrading/getUserForTradeAccount/${id}/BID`)
        .then((response) => {
          if (response.status === 200) {
            const buyerData = response?.data?.result.find(
              (item: any) => item.buyerUserId === userInfo?.uid
            );
            setBuyerRequrestData(buyerData);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {}
  };

  // const getCheckSellerList = async () => {
  //   try {
  //     axios
  //       .get(`/cardTrading/getUserForTradeAccount/${id}/ASK`)
  //       .then((response) => {
  //         if (response.status === 200) {
  //           const buyerData = response?.data?.result.find(
  //             (item: any) => item.sellerUserId === userInfo?.uid
  //           );
  //           if (buyerData != undefined && buyerData !="" ) setAllRequestsList([buyerData])
  //         }
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //       });
  //   } catch (error) {}
  // };


  const getCheckSellerList = () => {
    if (userInfo?.uid)
    axios.get(`/cardTrading/getInitiatedTradeForSaleAndGift/${userInfo?.uid}/${id}`).then((res) => {
      // console.log(res.data.result, "getSellandGiftlist");
      const datalist = res?.data?.result
      const buyerData :any = []
        datalist?.find((item: any) => 
      {
        if (item?.sellerUserId === userInfo?.uid && item?.transitionType != "GIFT") {
          buyerData.push(item)
        }
      });
      console.log(buyerData, datalist, "getbothdata")
      if (singalCardData?.cardId) getRewardTransactions(userInfo?.uid,datalist)
      setAllRequestsList(buyerData)      
    }).catch((err) => {
      // setOnsellOpen(true)
      console.log(err, "getSellandGiftlist");
    });
  }

  const getRewardTransactions = async (myID: any, datalist:any) => {
    console.log(myID, "myIDgetmyID")    
    try {
      const rewardTransactionsRef = collection(firestore,"reward_transactions");
      const rewardTransactionsQuery = query(rewardTransactionsRef,where("user", "==", myID));
      const snapshot = await getDocs(rewardTransactionsQuery);      
      const AllSameCard: any = [];      
      snapshot.forEach((cards) => { 
        console.log(cards.data(),"getcards.data()")
        let arrayData: any = datalist.filter((e: any) => {
          if (
            e.serialNumber == cards?.data()?.winData.firstRewardCardSerialNo &&
            !["REJECTED", "APPROVED", "EXPIRED"].includes(e.status)
          ) {
            console.log(e.buyerUserId, "e.buyerUserId");
            return e;
          }
        }); 
        console.log(arrayData,"arrayData")
        if (cards.data()?.winData?.firstRewardCardId == singalCardData?.cardId) {                         
          AllSameCard.push({
            ...cards.data(),            
            tradeCardData: arrayData[0],
          });
        }
      });

      console.log(AllSameCard,"getAllSameCard")
      const getSerialNo: any = []
      AllSameCard.map((itemvalue: any) => {
        if (itemvalue?.tradeCardData == undefined) {
          getSerialNo.push(itemvalue?.winData?.firstRewardCardSerialNo)
        }
      })
      setCommenSerialNo(getSerialNo)       
    } catch (error) {
      console.error("Error fetching reward transactions:", error);
    }
  };

  console.log(allRequestsList, "AllRequestsList")
  console.log(commenSerialNo, "commenSerialNo")
  
  const getAllRewardsOfUser = async (uid: any) => {
    try {
      const rewardsCollection = collection(firestore, "reward_transactions");
      const rewardsQuery = query(rewardsCollection, where("user", "==", uid));
      const querySnapshot = await getDocs(rewardsQuery);

      var winCards: {
        firstRewardCard: string;
        firstRewardCardCollection: string;
        firstRewardCardId: number;
        firstRewardCardSerialNo: string;
        firstRewardCardType: string;
        secondRewardExtraVotes: number;
        thirdRewardDiamonds: number;
      }[] = [];
      querySnapshot.forEach((doc) => {
        const data = doc.data();
        if (data?.winData?.firstRewardCardId) {
          winCards.push({ ...data.winData, ...data.transactionTime });
        }
      });

      setWinnerCard(winCards);
    } catch (error) {
      console.log("getAllRewardsOfUser Error", error);
    }
  };

  const getPriSerialNo = () => {
    var seriaNo;
    let PriSerialNo = winnerCard?.find((winCard: any, index: number) => {
      if (winCard?.firstRewardCardId == singalCardData?.cardId) {
        seriaNo = winCard?.firstRewardCardSerialNo;
        return "hello";
      }
    });
    return seriaNo;
  };

  useEffect(() => {
    if (userInfo?.uid) {
      (async () => {
        await getAllRewardsOfUser(userInfo?.uid);
      })();
    }
  }, [userInfo?.uid]);

  useEffect(() => {
    if (
      // userInfo?.uid &&
      id &&
      (seletcardHolderType == "" || seletcardHolderType == "Holders")
    ) {
      getFollwersList(id);
      getCheckBuyerList();
      getCheckSellerList();
    }
    getNftCard();
    handleTabClick(
      seletcardHolderType == "Holders"
        ? 0
        : seletcardHolderType == "Buyers"
        ? 1
        : seletcardHolderType == "Sellers"
        ? 2
        : 0
    );
    // alllist= getList({cardID:id})
  }, [id, userInfo?.uid, seletcardHolderType,singalCardData?.cardId]);
  useEffect(() => {
    // @ts-ignore

    // if (userInfo?.uid) {
      getCardData(id)
    // }
    // setSingalCardData(JSON.parse(localStorage.getItem("singalCardData")));
    return () => {
      setSeletcardHolderType("");
    };
    // getCardDetails();
  }, [userInfo?.uid]);

  const getCardData = async (cardId: any) => {
    setIsLoading(true);
    const getCollectionTypeRef = collection(firestore, "cardsDetails");
    const getOneCollection = query(
      getCollectionTypeRef,
      where("cardId", "==", cardId)
    );
    const querySnapshot = await getDocs(getOneCollection);

    let data = {};
    if (!querySnapshot.empty) {
      const firstDocument = querySnapshot.docs[0];
      data = { id: firstDocument.id, ...firstDocument.data(), myID: userInfo?.uid || "" };
    }
    setIsLoading(false);
    setSingalCardData(data)
  }

  useEffect(() => {
    (async () => {
      if (id) {
        axios
          .get(`/cardTrading/getCardDetails/${id}`)
          .then((res: any) => {
            console.log("AAAAAAAAAAA singlecarddata", res);
          })
          .catch((error: any) => {
            console.log("error", error);
          });
      }
    })();
  }, [id]);

  const handleTabClick = (index: number) => {
    setActiveTab(index);
  };

  const createBuyRequrst = async (
    data: any,
    callBack: any,
    isEdit?: any,
    getErrorMessage?: any
  ) => {        
    let cardData = { ...singalCardData, sno: [singalCardData?.sno ? singalCardData?.sno[0] : ""] };   
    let dataPayload = {
      ...data,
      sellerUserId: "",
      buyerUserId: userInfo?.uid,
      cardId: id,
      cardName: singalCardData?.cardName,
      buyerUserName: userInfo?.userName,
      serialNumber: "",   
      cardData: cardData
    };
    console.log(dataPayload, "cardDatasend123")
    await axios
      .post(`/cardTrading/openTradeAccount`, dataPayload)
      .then((res) => {
        if (res.status === 200) {
          if (callBack) callBack();
          showToast(res?.data?.message, ToastType.SUCCESS);
          getCheckBuyerList();    
          setSeletcardHolderType("Holders");
        }

      })
      .catch((err) => {
        console.log(err);
        getErrorMessage(err?.response?.data?.message);        
        // showToast(err?.response?.data?.message, ToastType.ERROR);
      });
  };

  const getRequrstList = async (type: string) => {
    setIsLoadingText(true);
    try {
      axios
        .get(`/cardTrading/getUserForTradeAccount/${id}/${type}`)
        .then((response) => {
          if (response.status === 200) {
            fetchData(response?.data?.result, type);
          }
        })
        .catch((err) => {
          showToast(err.response.data.message, ToastType.ERROR);
        });
    } catch (error) {}
  };

  async function fetchData(allList: any, type: any) {
    let allDataList: any = [];
    const promises = allList?.map(async (item: any) => {
      const getUserDetelist = await getUserData(
        type == "BID" ? item?.buyerUserId : item?.sellerUserId
      );
      return {
        buyerData: { ...item },
        ...getUserDetelist,
        status: getUserDetelist?.status?.name,
        leaders: getUserDetelist?.leader?.length || 0,
        subscribers: getUserDetelist?.subscribers?.length || 0,
      };
    });
    allDataList = await Promise.all(promises);
    if (type == "BID") {
      setAllBuyerList(allDataList);
      setIsLoadingText(false);
    } else {
      setAllSellerList(allDataList);
      setIsLoadingText(false);
    }
  }

  const EditBuyerData = async (
    data: any,
    callBack: any,
    isEdit?: any,
    getErrorMessage?: any,
    leaderData?: any
  ) => {    
    let dataPayload = {
      ...data,
      sellerUserId: buyerRequrestData?.sellerUserId,
      buyerUserId: buyerRequrestData?.buyerUserId,
      cardId: buyerRequrestData?.cardId,
      serialNumber: buyerRequrestData?.serialNumber,
      lastUpdatedBy: userInfo?.uid, 
    };

    try {
      await axios
        .put(
          `/cardTrading/updateOpenTrade/${
            buyerRequrestData?.id || buyerRequrestData?.accountId
          }`,
          dataPayload
        )
        .then((res) => {
          if (res.status === 200) {
            console.log(res, "get full response");
            if (callBack) callBack();
            showToast(res?.data?.message, ToastType.SUCCESS);
            getCheckBuyerList();
            setSeletcardHolderType("Holders");
            // getFollwersList(id)
          }          
        })
        .catch((err) => {
          console.log(err);
          getErrorMessage(err?.response?.data?.message);          
          // showToast(err?.response?.data?.message, ToastType.ERROR);
          
        });
    } catch (error) {
      console.log(error);
    }
  };

  const createSellRequrst = async (
    data: any,
    callBack: any,
    isEdit?: any,
    leader?: any,
    getErrorMessage?: any
  ) => {
    let cardData = { ...singalCardData, sno: [singalCardData?.sno ? singalCardData?.sno[0] : ""] , myID:userInfo?.uid }
    let dataPayload = {
      ...data,
      sellerUserId: userInfo?.uid,
      cardName: singalCardData?.cardName,
      cardId: id,
      serialNumber: commenSerialNo[0],
      sellerUserName: userInfo?.userName,
      cardData: cardData
    };
    console.log(dataPayload, "payloadview");
    await axios
      .post(`/cardTrading/openTradeAccount`, dataPayload)
      .then((res) => {        
          if (res.status === 200) {
            showToast(res?.data?.message, ToastType.SUCCESS);
            getCheckSellerList()
            if (callBack) callBack();
            setSeletcardHolderType("Holders");
        }
      })
      .catch((err) => {
        console.log(err);
        getErrorMessage(err?.response?.data?.message);        
        // showToast(err?.response?.data?.message, ToastType.ERROR);
      });
  };

  const submitTradeData = async (
    data: any,
    callBack: any,
    isEdit?: any,
    leaderData?: any,
    getErrorMessage?: any
  ) => {
    let dataPayload = {
      ...data,

      sellerUserId: leaderData?.sellerUserId,
      buyerUserId: leaderData?.buyerUserId,
      cardId: leaderData?.cardId,
      serialNumber: leaderData?.serialNumber,
      sellerUserName: leaderData?.sellerUserName || "",
      lastUpdatedBy: userInfo?.uid, 
    };

    console.log(dataPayload, "alldatadataPayload");
    try {
      await axios
        .put(
          `/cardTrading/updateOpenTrade/${
            leaderData?.id || leaderData?.accountId
          }`,
          dataPayload
        )
        .then((res) => {
          if (res.status === 200) {
            console.log(res, "get full response");
            if (callBack) callBack();
            showToast(res?.data?.message, ToastType.SUCCESS);
            // winnerCardData.current = ""
            getCheckSellerList();
            setSeletcardHolderType("Holders");
          }
        })
        .catch((err) => {
          console.log(err);
          getErrorMessage(err?.response?.data?.message);          
          // showToast(err?.response?.data?.message, ToastType.ERROR);
        });
    } catch (error) {
      console.log(error);
    }
  };



  const handleDelete = async (tradeInfo: any) => {
    console.log(tradeInfo,"tradeInfoallingo")
    let result = await confirmDelete();    
    if (result.isConfirmed) {      
      console.log('Item deleted', tradeInfo);
      const data = {
        accountId: tradeInfo?.id
      }
      axios.post(`/cardTrading/deleteOpenTrade`, data).then((res) => {
        // fetchData(activeTab)
        getCheckSellerList()
        handleAllRequestsClose()
        setAllRequestsList([])        
        showToast(res?.data?.message, ToastType.SUCCESS);
        setSeletcardHolderType("Holders");
      }).catch((err) => {
        // fetchData(activeTab)        
        showToast(err?.response?.data?.message, ToastType.ERROR);
      });
      // Your delete logic here, e.g., calling an API
    } else if (result.dismiss === Swal.DismissReason.cancel) {
      console.log('Delete action canceled');
    }
  };

  console.log(allSellerList, allBuyerList, "getresponse");

  return (
    <div className="">
      {isLoading && (
        <div
          style={{
            position: "fixed",
            height: "100%",
            // border: "2px solid red",
            display: "flex",
            textAlign: "center",
            justifyContent: "center",
            // top: '0px',
            backgroundColor: "rgba(0,0,0,0.7)",
            right: "0px",
            bottom: "0px",
            zIndex: "9999",
            overflow: "hidden",
            width: "100%",
            alignItems: "center",
          }}
        >
          <span
            className="loading position-fixed"
            style={{
              color: "white",
              zIndex: "2220px",
              fontSize: "1.5em",
              top: `${window.scrollY + 300}px`,

              // marginTop: `${window.screen.width > 767 ? "50px" : "240px"}`
            }}
          >
            {texts?.waitForIt}
          </span>
        </div>
      )}
      <div className="h-100 ">
        <CenterItem className="">
          {/* <div className="text-center">
            <p>{cards.cardType}</p>
          </div> */}
          <div
            style={{
              backgroundColor: "white",
              borderRadius: "0px 0px 87px 0px",
              boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
            }}
          >
            <SummerCard>
              {/* {nftAlbumData?.map((items:any, index:number) => {
              return ( */}
              <div
                className=""
                style={{
                  width: `${window.screen.width < 767 ? "70%" : "38%"}`,
                }}
              >
                {/* @ts-ignore */}

                {/* @ts-ignore */}
                {/* {items?.cards.map((item: any) => {
                      if (item?.cardId == id) {                        
                        return ( */}
                <>
                  <NftOneCard
                    DivClass={singalCardData?.cardType}
                    HeaderText={singalCardData?.cardType}
                    HeaderClass={`${singalCardData?.cardType}_text`}
                    Serie={singalCardData?.setName}
                    BackCardName={singalCardData?.cardName}
                    Rarity={singalCardData?.cardType}
                    CollectionType={singalCardData?.albumName}
                    Quantity={singalCardData?.quantity}
                    holderNo={singalCardData?.noOfCardHolders}
                    cardNo={`${
                      singalCardData?.cardName?.toUpperCase()?.slice(0, 2) +
                      singalCardData?.id?.slice(0, 2)
                    }`}
                    // GeneralSerialNo={`${((type)?.toUpperCase())?.slice(0, 3) + ((singalCardData?.cardName)?.toUpperCase())?.slice(0, 3) + singalCardData?.cardId}`}
                    // GeneralSerialNo={singalCardData?.sno}
                    GeneralSerialNo={
                      singalCardData?.albumName?.toUpperCase()?.replace(/\s+/g, '')?.slice(0, 2) +
                      singalCardData?.setName?.toUpperCase()?.replace(/\s+/g, '')?.slice(0, 2) +
                      singalCardData?.cardType?.toUpperCase()?.replace(/\s+/g, '')?.slice(0, 2)
                    }
                    // Disable={"CardDisebal"}
                    // When you pass CardDisebal this name then card is Disable
                    cardHeader={`${singalCardData?.cardName}`}
                    // cardNo={`${singalCardData.cardNo}`}
                    id={singalCardData?.cardId}
                    BackSideCard={BackSideCard}
                    // flipCard={backCards == singalCardData.id ? true : false}
                    flipCard={backCards?.includes(singalCardData?.cardId)}
                    ImgUrl={singalCardData?.cardImageUrl || ""}
                    VideoUrl={singalCardData?.cardVideoUrl || ""}
                    darkTheme={true}
                    Hide360Icon={true}
                  />
                </>
              </div>
            </SummerCard>
            <div className="d-flex justify-content-center  pt-2 pb-4">
              <Other
                onClick={() => {
                  // navigate(-1);
                  setAlbumOpen(singalCardData?.albumName);
                  navigate("/WallOfFame");
                }}
              >
                {translate("BACK TO COLLECTION")}
              </Other>
            </div>
            {userInfo?.uid && followersDetails?.length > 0 && <div className="d-flex justify-content-center  pt-2 pb-4">
              <div className="mx-3">
                {userInfo?.uid &&
                buyerRequrestData != undefined &&
                Object.keys(buyerRequrestData).length > 0 ? (
                  <Buttons.Primary
                    style={{
                      borderRadius: "50px",
                    }}
                      onClick={() => {                                              
                      setOpenModal("buyerDetails");
                    }}
                  >
                    Your buy offer
                  </Buttons.Primary>
                ) : (
                  <Buttons.Primary
                    style={{
                      borderRadius: "50px",
                    }}
                    onClick={() => {
                      setOpenModal("buyercards");
                    }}
                  >
                    Open Buy Offer
                  </Buttons.Primary>
                )}
              </div>
              <div className="mx-3">
                {
                  allRequestsList != undefined &&
                  allRequestsList?.length > 0 ? (
                  <Buttons.Primary
                    style={{
                      borderRadius: "50px",
                    }}
                    disabled={
                      !userInfo?.rewardStatistics?.cards.includes(
                        singalCardData?.cardName
                      )
                    }
                      onClick={() => {
                        if (allRequestsList?.length > 1) {                          
                          handleAllRequestsShow()
                        } else {                          
                          setOpenSellerModal("saleCardDetails");
                          setSellerRequrestData(allRequestsList[0])
                        }
                    }}
                  >
                    Your sell offer
                  </Buttons.Primary>
                ) : (
                  <Buttons.Primary
                    style={{
                      borderRadius: "50px",
                    }}
                    disabled={
                      !userInfo?.rewardStatistics?.cards.includes(
                        singalCardData?.cardName
                      )
                    }
                    onClick={() => {
                      setOpenSellerModal("buyercards");
                    }}
                  >
                    Open Sell Offer
                  </Buttons.Primary>
                )}
              </div>
            </div>}
          </div>
          <div>
            <div>
              {/* <div className="text-center my-3">
                <span
                  style={{
                    color: "black",
                    fontWeight: 500,
                    fontSize: "15px",
                  }}
                >
                  Card Holders
                </span>
              </div> */}
              <div className="tabsdiv">
                <div className="tab-buttonsdiv">
                  <button
                    onClick={() => {
                      handleTabClick(0);
                      setSeletcardHolderType("Holders");
                    }}
                    className={activeTab === 0 ? "active" : ""}
                  >
                    Holders
                  </button>
                  <button
                    onClick={() => {
                      handleTabClick(1);
                      setSeletcardHolderType("Buyers");
                      getRequrstList("BID");
                    }}
                    className={activeTab === 1 ? "active" : ""}
                  >
                    Buyers
                  </button>
                  <button
                    onClick={() => {
                      handleTabClick(2);
                      setSeletcardHolderType("Sellers");
                      getRequrstList("ASK");
                    }}
                    className={activeTab === 2 ? "active" : ""}
                  >
                    Sellers
                  </button>
                </div>
                <div className="tab-contentdiv">
                  {activeTab === 0 && (
                    <div>
                      {followersDetails?.length > 0 ? (
                        <Leaderboard
                          {...{
                            expanded: true,
                            leaders: followersDetails?.filter((leader: any) => {
                              return (
                                // @ts-ignore
                                leader?.status?.toLowerCase() ===
                                  chosen?.toLowerCase() || !chosen
                              );
                            }),
                            userInfo,
                            setChecked: setChecked(leaders, user, setIsLoading),
                            singalCardData,
                            buyerCards,
                            getFollwersList,
                          }}
                        />
                      ) : (
                        <>
                          {isLoadingText == true ? (
                            <div className="d-flex justify-content-center">
                              <p
                                className="loading text-center mb-2"
                                style={{
                                  color: "#6352e8",
                                  // top: `${window.scrollY + 300}px`,
                                }}
                              >
                                {texts?.waitForIt}
                              </p>
                            </div>
                          ) : (
                            <p className="text-center mb-2">Data Not found</p>
                          )}
                        </>
                      )}
                    </div>
                  )}
                  {activeTab === 1 && (
                    <div>
                      {allBuyerList?.length > 0 ? (
                        <Leaderboard
                          {...{
                            leaders: allBuyerList?.sort((a: any, b: any) => b?.buyerData?.coin - a?.buyerData?.coin),
                            userInfo,
                            setChecked: setChecked(leaders, user, setIsLoading),
                            singalCardData,
                            buyerCards,
                            getFollwersList,
                            setApprovedCard,
                            setCoinModelShow,
                          }}
                        />
                      ) : (
                        <>
                          {isLoadingText == true ? (
                            <div className="d-flex justify-content-center">
                              <p
                                className="loading text-center mb-2"
                                style={{
                                  color: "#6352e8",
                                  // top: `${window.scrollY + 300}px`,
                                }}
                              >
                                {texts?.waitForIt}
                              </p>
                            </div>
                          ) : (
                            <p className="text-center mb-2">
                              Not Found Any Buyer
                            </p>
                          )}
                        </>
                      )}
                    </div>
                  )}
                  {activeTab === 2 && (
                    <div>
                      {allSellerList?.length > 0 ? (
                        <Leaderboard
                          {...{
                            // expanded: true,
                            leaders: allSellerList?.sort((a: any, b: any) => a?.buyerData?.coin - b?.buyerData?.coin) ,
                            userInfo,
                            setChecked: setChecked(leaders, user, setIsLoading),
                            singalCardData,
                            buyerCards,
                            getFollwersList,
                            approvedCard,
                            setApprovedCard,
                            cardModalShow,
                            setCardModalShow,
                          }}
                        />
                      ) : (
                        <>
                          {isLoadingText == true ? (
                            <div className="d-flex justify-content-center">
                              <p
                                className="loading text-center mb-2"
                                style={{
                                  color: "#6352e8",
                                  // top: `${window.scrollY + 300}px`,
                                }}
                              >
                                {texts?.waitForIt}
                              </p>
                            </div>
                          ) : (
                            <p className="text-center mb-2">
                              Not Found Any Seller
                            </p>
                          )}
                        </>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </CenterItem>
        <>
          {openModal == "buyercards" && (
            <BuyerPopup
              openModal={openModal}
              setOpenModal={setOpenModal}
              tradeCrad={createBuyRequrst}
              // leader={leader}
            />
          )}

          {openModal == "buyerDetails" && (
            <BuyerPopup
              openModal={openModal}
              setOpenModal={setOpenModal}
              tradeCrad={EditBuyerData}
              leader={buyerRequrestData}
            />
          )}

          {openSellerModal == "buyercards" && (
            <CardSalePopup
              openModal={openSellerModal}
              setOpenModal={setOpenSellerModal}
              openPopupType={openPopupType}
              tradeCrad={createSellRequrst}
              // leader={item}
            />
          )}
          {openSellerModal == "saleCardDetails" && (
            <CardSalePopup
              openModal={openSellerModal}
              setOpenModal={setOpenSellerModal}
              tradeCrad={submitTradeData}
              leader={SellerRequrestData}
            />
          )}

          <Modal
            className=""
            show={cardModalShow}
            onHide={() => setCardModalShow(false)}
            // fullscreen="sm-down"
            backdrop="static"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            style={{ backgroundColor: "rgba(0,0,0,0.8)", zIndex: "2200" }}
            animation={false}            
            contentClassName={
              window.screen.width > 767
                ? `card-content modulebackground ForBigNft ${`${approvedCard?.cardDetails?.winData?.firstRewardCardType.toLowerCase()}BG`}`
                : `card-contentMob modulebackground ForBigNft ${`${approvedCard?.cardDetails?.winData?.firstRewardCardType.toLowerCase()}BG`}`
            }
          >
            <div className="d-flex justify-content-end"></div>
            <Modal.Body
              style={{
                padding: "0px",
              }}
            >
              <NFTTradeCard
                rewardTimer={{ data: approvedCard?.cardDetails?.winData }}
                approvedCard={approvedCard}
                handleCardClose={handleCardClose}
                setShareModalShow={setShareModalShow}
              />
            </Modal.Body>
          </Modal>
        </>
      </div>
      <NFTTradeCoin
        modalShow={coinModelShow}
        approvedCard={approvedCard}
        handleCoinClose={handleCoinClose}
      />
      <ShareModal
        shareModalShow={shareModalShow}
        setShareModalShow={setShareModalShow}
        url={url}
        shareText={shareText}
        // setAddPaxWalletPop={setAddPaxWalletPop}
      />

      <div>
        <Modal
          dialogClassName="modal-35w"
          show={requestsPopup}
          onHide={handleAllRequestsClose}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          style={{ opacity: 1, zIndex: 9999 }}
          className="borderColor"
          // animation={false}
          backdrop="static"
          contentClassName={window.screen.width <= 750 ? "w-100" : "w-50"}
        >
          <div className="d-flex justify-content-end">
            <button
              type="button"
              className="btn-close "
              aria-label="Close"
              onClick={handleAllRequestsClose}
            ></button>
          </div>
          <Modal.Body>
            <div className="w-100 mb-4 text-center">
              <strong className="">All offers</strong>
            </div>
            <div className="viewListdataBig">
              {allRequestsList?.length > 0 ? <>
                {
                  allRequestsList.map((item: any, index: number) => {
                    return <div key={index + item?.id}>
                      <div className="viewListdata">                        
                        <InfoDiv>
                          {/* <p>User Name : {item?.userName}</p> */}
                          <p>Card Name : {item?.cardName}</p>
                          <p>Serial no. : {item?.serialNumber}</p>
                          <p>{item?.coin} VTE</p>                         
                        </InfoDiv>
                        <div>                          
                          <TradeButton
                            onClick={(e) => {
                              // getViewDetelist(item?.transitionType, item)
                              setOpenSellerModal("saleCardDetails");
                              setSellerRequrestData(item)
                              handleAllRequestsClose()
                            }}
                          >
                            <TradeText>Edit</TradeText>
                          </TradeButton>
                          <TradeButton
                            style={{
                              backgroundColor: "#e05858",
                              marginTop: "5px",
                            }}
                            onClick={(e) => {
                              // getTradeDetelist(item, "REJECTED")
                              handleDelete(item)
                              handleAllRequestsClose()
                            }}
                          >
                            <TradeText>Cancel</TradeText>
                          </TradeButton>
                        </div>
                      </div>
                    </div>
                  })
                }
              </> :
                <div className="text-center">
                  Offers not found
                </div>}
            </div>
            {/* <p className="text-center">Pls give me this card</p> */}
            <div className="w-100 d-flex justify-content-around mt-4">
              <TradeButtons
                style={{
                  backgroundColor: "#6458e5",
                }}
                onClick={() => {
                  handleAllRequestsClose()
                }}
              >
                Close
              </TradeButtons>
            </div>
          </Modal.Body>
        </Modal>
      </div> 

    </div>
  );
};

export default SingalCard;
