/** @format */

import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import NftOneCard from "../NftOneCard";

import "../styles.css";
import SwiperBar from "../SwiperBar";
import UserContext from "Contexts/User";
import { Col, Form, Row } from "react-bootstrap";
import { texts } from "Components/LoginComponent/texts";
import { debounce, trim } from "lodash";
import { divideArray, divideArray1 } from "common/utils/helper";
import SetsScreen from "Pages/SetsScreen";
import { db, firestore, functions } from "../../firebase";
import { collection, getDocs, query, where } from "firebase/firestore";
import NftLodding from "Pages/NftLodding";
import axios from "axios";
import BuyerPopup from "Components/Profile/CardTrade/BuyerPopup";
import { showToast } from "App";
import { ToastType } from "Contexts/Notification";
import { httpsCallable } from "firebase/functions";

// import { Firestore } from "firebase/firestore";

export type getCardType = {
  id?: any;
};

const GalleryType = styled.div`
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  // justify-content:space-around;
  justify-content: ${window.screen.width > 767 ? "space-between" : "center"};
  color: black;
  margin-top: 10px;
  & div {
    // border: 1px solid #5f4ce3;
    width: ${window.screen.width < 767 ? "80%" : "340px"};
    height: ${window.screen.width < 767 ? "91px" : "100px"};
    // height:71px;
    margin: 10px 0px;
    cursor: pointer;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px,
      rgba(0, 0, 0, 0.3) 0px 18px 36px -18px;
    // padding: 20px 20px;
    text-align: center;
    $ p {
    }
  }
`;

const SummerCard = styled.div`
  display: flex;
  justify-content: center;
  // border:1px solid red;
  flex-wrap: wrap;
  background-color: #f8f9fa;
`;

const Video = styled.video`
  width: 100%;
  // max-width: 300px;
  height: auto;
  margin: 0 auto;
  // border-radius: 20px;
`;

const Album: React.FC<{ userId: string; isFollower?: boolean }> = ({
  userId,
  isFollower = false,
}) => {
  const navigate = useNavigate();
  const { userInfo } = useContext(UserContext);
  const [collectionType, setCollectionType] = useState<any>();
  const [backCards, setBackCards] = useState<any>([]);
  const [equalPart, setEqualPart] = useState<any>([]);
  const [winerCard, setWinerCard] = useState<any>([]);
  const [myCards, setMyCards] = useState<any>(false);
  const [setsValue, setSetsValue] = useState<any>([]);
  const [cardNameNew, setCardNameNew] = useState<any>([]);
  const [sameCards, setSameCards] = useState<any>({});
  const [isLoading, setIsLoading] = useState(false);
  const [myFilter, setMyFilter] = useState<any>([]);
  const [allCards, setAllCards] = useState<any>([]);
  const [searchValue, setSearchValue] = useState<any>("");
  const [collectionValue, setCollectionValue] = useState<any>("none");
  const [collectionSetValue, setCollectionSetValue] = useState<any>("none");
  const [collectionTypeValue, setCollectionTypeValue] = useState<any>("all");
  const [collectionCardValue, setCollectionCardValue] = useState<any>("none");
  const [displayMyCards, setDisplayMyCards] = useState<boolean>(false);
  const [cardLoading, setCardLoading] = useState<boolean>(false);
  const [winnerCardId, setWinnerCardId] = useState<string[]>([]);
  const [curruntPage, setCurruntPage] = useState<any>(1);
  const [totalPage, setTotalPage] = useState<any>(4);
  const [showMyCards, setShowMyCards] = useState<any>([]);
  const [searchCard, setSearchCard] = useState<any>([]);
  const [openModal, setOpenModal] = useState<any>("");
  const [cardItem, setCradItem] = useState<any>("");
  const [buyerRequrestData, setBuyerRequrestData] = useState<any>({});
  const [openOffercardId, setOpenOffercardId] = useState<any>("");
  const [cardSingalData, setCardSingalData] = useState<any>({});
  const [responseData, setResponseData] = useState<any>([]);

  const parameters = new URLSearchParams(window.location.search);
  const pathName = window.location.pathname;
  let tempFilter = allCards;

  useEffect(() => {
    setIsLoading(true);
    // @ts-ignore
    getAllRewardsOfUser(`${userId}`);
    getCollectionType();
    // getCardDetails();
  }, []);

  const getAllRewardsOfUser = async (uid: string) => {
    try {
      const rewardsCollection = collection(firestore, "reward_transactions");
      const rewardsQuery = query(rewardsCollection, where("user", "==", uid));
      const querySnapshot = await getDocs(rewardsQuery);

      var winCards: {
        firstRewardCard: string;
        firstRewardCardCollection: string;
        firstRewardCardId: number;
        firstRewardCardSerialNo: string;
        firstRewardCardType: string;
        secondRewardExtraVotes: number;
        thirdRewardDiamonds: number;
      }[] = [];
      querySnapshot.forEach((doc) => {
        const data = doc.data();
        if (data?.winData?.firstRewardCardId) {
          winCards.push({ ...data.winData, ...data.transactionTime });
        }
      });

      setWinerCard(winCards);
    } catch (error) {
      console.log("getAllRewardsOfUser Error", error);
    }
  };

  const getCollectionType = async () => {
    try {
      const collectionTypeRef = collection(firestore, "nftGallery");
      const querySnapshot = await getDocs(collectionTypeRef);

      const data: any = [];
      querySnapshot.forEach((doc) => {
        data.push({ id: doc.id, ...doc.data() });
      });

      setIsLoading(false);
      setCollectionType(data);
      // setCardShow(false);
    } catch (error) {
      console.error("Error fetching collection type:", error);
    }
  };

  const getCardDetails = async (albumName: any) => {
    if (albumName) {
      try {
        const getCollectionTypeRef = collection(firestore, "cardsDetails");
        const getOneCollection = query(
          getCollectionTypeRef,
          where("albumName", "==", albumName)
        );
        const querySnapshot = await getDocs(getOneCollection);

        const data: any = [];
        querySnapshot.forEach((doc) => {
          data.push({ id: doc.id, ...doc.data() });
        });

        // Sorting by albumName and setName
        data.sort(
          (a: any, b: any) =>
            a.albumName.localeCompare(b.albumName) ||
            a.setName.localeCompare(b.setName)
        );

        console.log("I am working", data);
        setAllCards(data);
        setCollectionValue(albumName);
        setSetsValue([]);
        setCollectionSetValue("none");
        setCollectionTypeValue("all");
        setCollectionCardValue("none");
      } catch (error) {
        console.error("Error fetching card details:", error);
      }
    } else {
      setCollectionValue(albumName);
      setSetsValue([]);
      setCollectionSetValue("none");
      setCollectionTypeValue("all");
      setCollectionCardValue("none");
    }
  };

  const BackSideCard = (value: string | number) => {
    if (backCards.includes(value)) {
      let allBackCard = [...backCards];
      allBackCard.splice(backCards.indexOf(value), 1);
      setBackCards(allBackCard);
    } else {
      setBackCards([...backCards, value]);
    }
  };

  const CheckCardDisable = (cardId: any) => {
    var disableCard;

    let cardTrue = winerCard?.find((winCard: any, index: number) => {
      if (winCard?.firstRewardCardId != cardId) {
        disableCard = "CardDisebal";
        return false;
      }
      if (winCard?.firstRewardCardId == cardId) {
        disableCard = undefined;
        return true;
      }
    });
    return disableCard;
  };

  const getMintedTime = (cardId: any) => {
    var getMIntedTime;
    console.log(winerCard, "winerCardcheck");

    let mintedTime = winerCard?.find((winCard: any, index: number) => {
      if (winCard?.firstRewardCardId == cardId) {
        const date = new Date(winCard?.seconds * 1000);
        getMIntedTime = date.toLocaleString();
        return true;
      }
    });
    return getMIntedTime;
  };

  const getPriSerialNo = (cardId: any) => {
    var seriaNo;
    let PriSerialNo = winerCard?.find((winCard: any, index: number) => {
      if (winCard?.firstRewardCardId == cardId) {
        seriaNo = winCard?.firstRewardCardSerialNo;
        return "hello";
      }
    });
    return seriaNo;
  };

  useEffect(() => {
    if (localStorage.getItem("filterCollection")) {
      const filterCollection = JSON.parse(
        localStorage.getItem("filterCollection") || ""
      );
      // setCollectionValue(filterCollection?.name || 'none');
      // setCollectionCardValue(filterCollection?.card || 'none');
      handleBacktoModule(filterCollection?.name, filterCollection?.card);
      localStorage.removeItem("filterCollection");
    }
  }, [localStorage.getItem("filterCollection")]);

  useEffect(() => {
    if (parameters.get("collectionName")) {
      let NewCollectionName = parameters
        .get("collectionName")
        ?.replace("-", " ");
      handleBacktoModule(NewCollectionName);
    }
  }, [
    parameters.get("collectionName"),
    parameters.get("setName"),
    parameters.get("cardName"),
  ]);

  const handleBacktoModule = async (collectionName: any, cardName?: any) => {
    // getCardDetails(collectionName);
    try {
      const getCollectionTypeRef = collection(firestore, "cardsDetails");
      const getOneCollection = query(
        getCollectionTypeRef,
        where("albumName", "==", collectionName)
      );
      const querySnapshot = await getDocs(getOneCollection);

      const data: any = [];
      querySnapshot.forEach((doc) => {
        data.push({ id: doc.id, ...doc.data() });
      });

      // Sorting by albumName and setName
      data.sort(
        (a: any, b: any) =>
          a.albumName.localeCompare(b.albumName) ||
          a.setName.localeCompare(b.setName)
      );

      console.log("I am working", data);
      setAllCards(data);
      setCollectionValue(collectionName);
      setSetsValue([]);
      setCollectionSetValue(parameters.get("setName") || "none");
      // setCollectionSetValue('none');
      setCollectionTypeValue("all");
      setCollectionCardValue(
        cardName || parameters.get("cardName")?.replace("-", " ") || "none"
      );
    } catch (error) {
      console.error("Error fetching card details:", error);
    }
  };

  const onCollectionChange = async (collectionName: any) => {
    try {
      const getAlbumId =
        collectionType &&
        collectionType?.find((item: any) => item.albumName === collectionName);

      if (getAlbumId) {
        setIsLoading(true);

        const getSetsTypeRef = collection(
          firestore,
          "nftGallery",
          getAlbumId.id,
          "setDetails"
        );

        const querySnapshot = await getDocs(getSetsTypeRef);

        const data: any = [];
        querySnapshot.forEach((doc) => {
          data.push({ id: doc.id, ...doc.data() });
        });

        setIsLoading(false);
        setSetsValue(data);
      }
    } catch (error) {
      setIsLoading(false);
      console.error("Error fetching sets type:", error);
    }
  };

  useEffect(() => {
    onCollectionChange(collectionValue);
  }, [collectionValue, collectionType]);

  useEffect(() => {
    setWinnerCardId(() => {
      return winerCard?.map((WinerItem: any) => WinerItem?.firstRewardCardId);
    });
  }, [winerCard]);

  //filter
  useEffect(() => {
    // var tempFilter = allCards;
    if (parameters.get("collectionName") && pathName?.includes("followerProfile")) {      
      navigate(`/followerProfile/CollectiblesAlbum`)    
    }
    if (parameters.get("collectionName") && pathName?.includes("profile/CollectiblesAlbum")) {      
      navigate(`/profile/CollectiblesAlbum`)    
    }
    if (collectionValue == "none" && searchValue !== "") {
      setIsLoading(true);
      debouncedSearch(searchValue.toLowerCase());
    } else if (collectionValue == "none" && displayMyCards == true) {
      setIsLoading(true);
      ShowMyCards();
    } else {
      if (collectionValue !== "none") {
        tempFilter = tempFilter.filter(
          (value: any) =>
            value.albumName.toLowerCase() === collectionValue.toLowerCase()
        );
      }
      if (collectionSetValue !== "none") {
        tempFilter = tempFilter.filter(
          (value: any) => value.setId === collectionSetValue
        );
      }
      setCardNameNew(
        [...tempFilter].sort((a: any, b: any) =>
          a.cardName.localeCompare(b.cardName)
        )
      );

      if (searchValue !== "") {
        tempFilter = tempFilter.filter((value: any) =>
          value.cardName.toLowerCase().includes(searchValue.toLowerCase())
        );
      }
      if (collectionTypeValue !== "all") {
        tempFilter = tempFilter.filter(
          (value: any) =>
            value.cardType.toLowerCase() === collectionTypeValue.toLowerCase()
        );
      }
      if (collectionCardValue !== "none") {
        tempFilter = tempFilter.filter(
          (value: any) =>
            value.cardName.toLowerCase() === collectionCardValue.toLowerCase()
        );
      }
      if (displayMyCards) {
        let winnerCardId = winerCard?.map(
          (WinerItem: any) => WinerItem?.firstRewardCardId
        );
        tempFilter = tempFilter.filter((value: any) =>
          winnerCardId.includes(value?.cardId)
        );
      }
    }
    setMyFilter(divideArray1(tempFilter, 4));
    return () => {debouncedSearch.cancel()
      setIsLoading(false)
    };
  }, [
    searchValue,
    collectionValue,
    collectionSetValue,
    collectionTypeValue,
    collectionCardValue,
    displayMyCards,
    allCards,
    winerCard,    
  ]);

  const debouncedSearch = debounce(async (value) => {
    if (value.trim() !== "" && value?.trim()?.length > 2) {
      // const capitalizedQuery = value.charAt(0).toUpperCase() + value.slice(1);
      const capitalizedQuery = value
        .split(" ")
        .map((word: any) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ");
      const q = query(
        collection(db, "cardsDetails"),
        where("cardName", ">=", capitalizedQuery),
        where("cardName", "<=", capitalizedQuery + "\uf8ff")
      );
      const querySnapshot = await getDocs(q);
      const results = querySnapshot.docs.map((doc) => doc.data());
      console.log(results, "getresults");
      setCardNameNew(
        [...results].sort((a: any, b: any) =>
          a.cardName.localeCompare(b.cardName)
        )
      );
      // setSearchCard(results)
      setIsLoading(false);
      setMyFilter(divideArray1(results, 4));
    } else {
      console.log("not found TropicSip", "getresults");
      setIsLoading(false);
      setSearchCard([]);
    }
  }, 300);

  const myCardsShow = useRef([]);
  const ShowMyCards = async () => {
    if (userInfo?.uid)
      axios
        .get(
          `album/available/${
            isFollower ? userId : userInfo?.uid
          }?page=${curruntPage}&pageSize=20`
        )
        .then((response) => {
          let data = response.data.result;
          let allshowcards = [...showMyCards, ...response.data.result];
          setShowMyCards(allshowcards);
          setTotalPage(response?.data?.pagination?.totalPages);
          setCurruntPage(curruntPage + 1);
          setIsLoading(false);
          setCardLoading(false);
          setMyFilter(divideArray1(allshowcards, 4));
        })
        .catch((err) => {
          console.error("Error fetching collection type:", err);
          setIsLoading(false);
          setCardLoading(false);
        });
  };

  const handleInputChange = (e: any) => {
    setSearchValue(e.target.value);
  };

  let addAlbumSeparator = "";

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (isFollower) {
          const userRef = collection(firestore, "users");
          const userQuery = query(userRef, where("uid", "==", userId));
          const snapshot = await getDocs(userQuery);

          const data: any = [];
          snapshot.forEach((doc) => {
            data.push({ ...doc.data() });
          });

          getsamecard(data[0]);
        } else {
          getsamecard(userInfo && userInfo);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [userInfo?.rewardStatistics?.cards]);

  const getsamecard = (data: any) => {
    console.log(data, "sameCards i am every time calling");
    var commonCard = {};
    // @ts-ignore
    const allCards = data?.rewardStatistics?.cards;
    allCards?.map((item: any, index: number) => {
      // @ts-ignore
      commonCard = {
        ...commonCard,
        // @ts-ignore
        [item]: commonCard[item] ? commonCard[item] + 1 : 1,
      };
    });
    console.log(data, "commonCard");
    setSameCards(commonCard);
  };


  const getCardItem = () => {
    let data = {};
    if (Object.keys(myFilter).length > 0) {
      Object.keys(myFilter).map((albumName: any, index: any) => {
        return (data = myFilter[albumName][0][0]);
      });
    } else {
      data = {};
    }
    return data;
  };

  const getCheckBuyerList = async (id: getCardType) => {
    let cardData: any = id ? { id: id } : getCardItem();
    try {
      axios
        .get(`/cardTrading/getUserForTradeAccount/${cardData?.id}/BID`)
        .then((response) => {
          if (response.status === 200) {
            const buyerData = response?.data?.result.find(
              (item: any) => item.buyerUserId === userInfo?.uid
            );
            // setBuyerRequrestData(buyerData);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {}
  };

  const onhandleClickToOpen = (type: any, data: any) => {
    console.log("fdf", type, data);
  };

  const createBuyRequrst = async (data: any, callBack: any, isEdit?: any, getErrorMessage?: any,) => {  
    let cardData = { ...cardSingalData, sno: [cardSingalData?.sno ? cardSingalData?.sno[0] : ""]}
    let dataPayload = {
      ...data,
      sellerUserId: "",
      buyerUserId: userInfo?.uid,
      cardId: openOffercardId?.cardId,
      cardName: openOffercardId?.cardName,
      buyerUserName: userInfo?.userName,
      serialNumber: "",
      lastUpdatedBy: userInfo?.uid,
      cardData: cardData,
    };
    
    await axios
      .post(`/cardTrading/openTradeAccount`, dataPayload)
      .then((res) => {
        if (res.status === 200) {
          if (callBack) callBack();
          showToast(res?.data?.message, ToastType.SUCCESS);
          setResponseData(res?.data?.result)
          // let carddata: any = getCardItem();
          // getCheckBuyerList(carddata?.id);
          // getFollwersList(id)
        }
      })
      .catch((err) => {
        console.log(err);
        getErrorMessage(err?.response?.data?.message)
        // showToast(err?.response?.data?.message, ToastType.ERROR);
      });
  };

  const EditBuyerData = async (
    data: any,
    callBack: any,
    isEdit?: any,
    getErrorMessage?: any,    
  ) => {
    let dataPayload = {
      ...data,
      sellerUserId: buyerRequrestData?.sellerUserId,
      buyerUserId: buyerRequrestData?.buyerUserId,
      cardId: buyerRequrestData?.cardId,
      cardName: buyerRequrestData?.cardName,
      serialNumber: buyerRequrestData?.serialNumber,
      lastUpdatedBy: userInfo?.uid, 
    };    
    try {
      await axios
        .put(
          `/cardTrading/updateOpenTrade/${
            buyerRequrestData?.id || buyerRequrestData?.accountId
          }`,
          dataPayload
        )
        .then((res) => {
          if (res.status === 200) {
            console.log(res, "get full response");
            if (callBack) callBack();
            showToast(res?.data?.message, ToastType.SUCCESS);
            setResponseData(res?.data?.result);
            // getCheckBuyerList(carddata?.id);
            // getFollwersList(id)
          }
        })
        .catch((err) => {
          console.log(err);
          getErrorMessage(err?.response?.data?.message)
          // showToast(err?.response?.data?.message, ToastType.ERROR);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const getList = httpsCallable(functions, `cardHolderListing`);
  

  const OpenPopupforBuyCard = async (type: any, leader: any, offerCardId: any, setButtonData?:any,cardData?:any) => { 
    console.log(type,"typeleaderallvalue")
    // getFollwersList(offerCardId?.cardId)  
    setButtonData(true);
    await getList({ cardId:offerCardId?.cardId })
      .then(async (list: any) => {
        console.log(list.data, "getfull card holder list")
        if (list?.data.length > 0) {
          setButtonData(false);
          setOpenOffercardId(offerCardId)
          setCardSingalData(cardData)
          if (type == "buyerDetails") {
            setOpenModal(type)
          }
          if (type == "buyercards") {
            setOpenModal(type)
          }
        } else {
          setButtonData(false);
          showToast("This card is not assigned to any cardholder.", ToastType.ERROR);
        }        
      })
      .catch((error: any) => {
        setButtonData(false);
        console.log(`error: ${JSON.stringify(error)}`);
        // setIsLoadingText(false);
      });
  }

  return (
    <div className="gr_bg" style={{ background: "white", minHeight: "80vh" }}>
      <div className="d-flex justify-content-center flex-wrap w-100 py-4 ipad_row_gap">
        <input
          type="text"
          onChange={(e) => {
            // setSearchValue(trim(e.target.value));
            handleInputChange(e);
          }}
          placeholder="SEARCH..."
          className="py-2 mx-2 color-back"
          style={{ width: "200px" }}
        />
        <div className={`${window.screen.width < 767 ? "py-3 d-flex" : ""}`}>
          <select
            className="color-back py-2 mx-1"
            value={collectionValue}
            onChange={(e) => {
              getCardDetails(e.target.value);
            }}
            style={{
              width: "155px",
              textTransform: "uppercase",
            }}
          >
            <option value="none"> {texts.SelectCollection} </option>
            {collectionType?.map((data: any, index: number) => {
              return (
                <option
                  selected
                  value={data?.albumName}
                  key={index}
                  style={{ textTransform: "uppercase" }}
                >
                  {" "}
                  {data?.albumName}{" "}
                </option>
              );
            })}
          </select>
          <select
            className="color-back py-2 mx-1"
            value={collectionSetValue}
            onChange={(e) => {
              setCollectionSetValue(e.target.value);
            }}
            style={{
              width: "155px",
              textTransform: "uppercase",
            }}
          >
            <option value="none"> {texts.SelectSets} </option>
            {setsValue?.map((data: any, index: number) => {
              return (
                <option
                  selected
                  value={data?.id}
                  key={index}
                  style={{ textTransform: "uppercase" }}
                >
                  {" "}
                  {data?.setName?.toUpperCase()}
                </option>
              );
            })}
          </select>
        </div>
        <div className={`${window.screen.width < 767 ? "" : ""}`}>
          <select
            name="type"
            id="type"
            className="color-back mx-1 py-2"
            onChange={(e) => {
              setCollectionTypeValue(e.target.value);
            }}
            value={collectionTypeValue}
            style={{
              width: "155px",
              textTransform: "uppercase",
            }}
          >
            {collectionValue != "none" ? (
              <>
                <option value="all" style={{ textTransform: "uppercase" }}>
                  {" "}
                  {texts.SelectType}{" "}
                </option>
                <option value={`${texts.Legendary}`}>
                  {" "}
                  {texts.Legendary}{" "}
                </option>
                <option value={`${texts.Rare}`}> {texts.Rare} </option>
                <option value={`${texts.Epic}`}> {texts.Epic} </option>
                <option value={`${texts.UNCommon}`}> {texts.UNCommon} </option>
                <option value={`${texts.Common}`}> {texts.Common} </option>
              </>
            ) : (
              <option value="all"> {texts.SelectType} </option>
            )}
          </select>

          <select
            className="color-back py-2 mx-1"
            onChange={(e) => {
              setCollectionCardValue(e.target.value);
            }}
            value={collectionCardValue}
            style={{
              width: "155px",
              textTransform: "uppercase",
            }}
          >
            <option value="none"> {texts.SelectName} </option>
            {collectionValue !== "none" &&
              cardNameNew?.map((data: any, index: number) => {
                return (
                  <option
                    selected
                    value={data?.cardName}
                    key={index}
                    style={{ textTransform: "uppercase" }}
                  >
                    {" "}
                    {`${data?.cardName}`}
                  </option>
                );
              })}
          </select>
        </div>
        {
          <div className="d-flex  justify-content-start align-items-center ">
            <Form.Check
              style={{ fontSize: "20px", marginRight: "10px" }}
              type="checkbox"
              id={`default-checkbox`}
              onClick={(e) => {
                setDisplayMyCards((prev) => !prev);
                if (displayMyCards) {
                  setShowMyCards([]);
                  setTotalPage(4);
                  setCurruntPage(1);
                }
                setMyCards(!myCards);
              }}
            />
            <label htmlFor="default-checkbox" className="text-white"> {texts.AvailableCards} </label>
          </div>
        }
      </div>
      {isLoading && collectionType !== "" && <NftLodding />}
      {collectionValue !== "none" &&
        collectionSetValue == "none" &&
        collectionCardValue == "none" &&
        collectionTypeValue == "all" &&
        !displayMyCards &&
        searchValue == "" && (
          <>
            <div className="w-100 d-flex">
              <div
                className={`${
                  window.screen.width > 767 ? "" : ""
                } d-flex justify-content-between flex-wrap w-100`}
                style={{}}
              >
                {setsValue.map((item: any, index: number) => {
                  return (
                    <SetsScreen
                      type="album"
                      onSelectName={setCollectionCardValue}
                      allCardNew={allCards}
                      setsValue={item}
                      winerCard={winerCard}
                      getCheckBuyerList={getCheckBuyerList}
                      isFollower={isFollower}
                    />
                  );
                })}
              </div>
            </div>
          </>
        )}

      {displayMyCards ||
      collectionSetValue != "none" ||
      collectionCardValue != "none" ||
      collectionTypeValue != "all" ||
      searchValue != "" ? (
        <>
          {Object.keys(myFilter)?.length > 0 ? (
            <SummerCard className="mt-4">
              {Object.keys(myFilter).map((albumName, index) => {
                return (
                  <React.Fragment key={index}>
                    <div
                      className="w-100 m-auto row pt-3"
                      style={{
                        borderTop:
                          index !== 0 && addAlbumSeparator !== albumName
                            ? "3px solid #bebac7"
                            : "",
                      }}
                    >
                      {collectionValue === "none" && (
                        <div
                          className="col-sm-2 d-flex justify-content-center align-items-center"
                          style={{
                            transform:
                              window.screen.width > 575 ? "rotate(270deg)" : "",
                            color: "#5f4de4",
                            fontSize: window.screen.width > 575 ? "4em" : "2em",
                            overflow: "visible",
                            wordWrap: "normal",
                            textTransform: "uppercase",
                          }}
                        >
                          {albumName}
                        </div>
                      )}
                      <div
                        className={
                          collectionValue === "none" ? "col-sm-10" : "col-sm-12"
                        }
                      >
                        {myFilter[albumName]?.map(
                          (cardPart: any, ind: number) => {
                            return (
                              <SwiperBar
                                slideSize={collectionValue === "none" ? 4 : 5}
                              >
                                {cardPart?.map((item: any, index: number) => {
                                  if (addAlbumSeparator !== item.albumName) {
                                    console.log(item.albumName, "adding");
                                    addAlbumSeparator = item.albumName;
                                  }
                                  if (myCards) {
                                    console.log("CHECK MYCARD 111 IF");
                                    return (
                                      <NftOneCard
                                        key={index}
                                        DivClass={item?.cardType}
                                        HeaderText={item?.cardType}
                                        HeaderClass={`${item?.cardType}_text`}
                                        Serie={item?.setName || "Set" + index}
                                        BackCardName={item?.cardName}
                                        Rarity={item?.cardType}
                                        Quantity={`${
                                          sameCards[item?.cardName]
                                        } / ${item?.totalQuantity}`}
                                        holderNo={item?.noOfCardHolders}
                                        // cardNo={`${((item?.setName)?.toUpperCase())?.slice(0, 3) + item?.setId}`}
                                        // cardNo={item?.sno[index]}
                                        ShowQuantity={`${
                                          sameCards[item?.cardName] || 1
                                        }`}
                                        GeneralSerialNo={
                                          item?.albumName?.toUpperCase()?.replace(/\s+/g, '')?.slice(0, 2) +
                                          item?.setName?.toUpperCase()?.replace(/\s+/g, '')?.slice(0, 2) +
                                          item?.cardType?.toUpperCase()?.replace(/\s+/g, '')?.slice(0, 2)
                                        }
                                        cardNo={`${
                                          item?.cardName
                                            ?.toUpperCase()
                                            ?.slice(0, 2) +
                                          item?.id?.slice(0, 2)
                                        }`}
                                        // GeneralSerialNo={}
                                        CollectionType={
                                          item?.albumName || "LEGENDARY"
                                        }
                                        MintedTime={getMintedTime(item?.cardId)}
                                        PrivateSerialNo={getPriSerialNo(
                                          item?.cardId
                                        )}
                                        Disable={
                                          winerCard.length
                                            ? CheckCardDisable(item?.cardId)
                                            : "CardDisebal"
                                        }
                                        userId={userId}
                                        isFollower={isFollower}
                                        // Disable={"CardDisebal"}
                                        cardHeader={`${item?.cardName}`}
                                        id={item?.cardId}
                                        BackSideCard={BackSideCard}
                                        fulldata={item}
                                        flipCard={backCards?.includes(
                                          item?.cardId
                                        )}
                                        ImgUrl={item?.cardImageUrl || ""}
                                        VideoUrl={item?.cardVideoUrl || ""}
                                        OpenPopupforBuyCard={OpenPopupforBuyCard}
                                      />
                                    );
                                  } else {
                                    return (
                                      <NftOneCard
                                        key={index}
                                        DivClass={item?.cardType}
                                        HeaderText={item?.cardType}
                                        HeaderClass={`${item?.cardType}_text`}
                                        Serie={
                                          item?.setName || "Set" + (index + 1)
                                        }
                                        BackCardName={item?.cardName}
                                        Rarity={item?.cardType}
                                        // Quantity={item?.totalQuantity}
                                        holderNo={item?.noOfCardHolders}
                                        // cardNo={`${((item?.setName)?.toUpperCase())?.slice(0, 3) + item?.setId}`}
                                        // cardNo={item?.sno[index]}
                                        // Quantity={`${getTotalSameCard(item?.id)} / ${item?.totalQuantity}`}
                                        ShowQuantity={`${
                                          sameCards[item?.cardName] || 1
                                        }`}
                                        Quantity={`${
                                          sameCards[item?.cardName] || 1
                                        } / ${item?.totalQuantity}`}
                                        GeneralSerialNo={
                                          item?.albumName?.toUpperCase()?.replace(/\s+/g, '')?.slice(0, 2) +
                                          item?.setName?.toUpperCase()?.replace(/\s+/g, '')?.slice(0, 2) +
                                          item?.cardType?.toUpperCase()?.replace(/\s+/g, '')?.slice(0, 2)
                                        }
                                        cardNo={`${
                                          item?.cardName
                                            ?.toUpperCase()
                                            ?.slice(0, 2) +
                                          item?.id?.slice(0, 2)
                                        }`}
                                        // GeneralSerialNo={`${((item.collectionName)?.toUpperCase())?.slice(0, 3) + ((item?.setName)?.toUpperCase())?.slice(0, 3) + item?.setId}`}
                                        MintedTime={getMintedTime(item?.cardId)}
                                        PrivateSerialNo={getPriSerialNo(
                                          item?.cardId
                                        )}
                                        Disable={
                                          winerCard.length
                                            ? CheckCardDisable(item?.cardId)
                                            : "CardDisebal"
                                        }
                                        userId={userId}
                                        // CollectionType={item?.collectionName}
                                        CollectionType={
                                          item?.albumName || "LEGENDARY"
                                        }
                                        // Disable={"CardDisebal"}
                                        cardHeader={`${item?.cardName}`}
                                        id={item?.cardId}
                                        BackSideCard={BackSideCard}
                                        fulldata={item}
                                        flipCard={backCards?.includes(
                                          item?.cardId
                                        )}
                                        ImgUrl={item?.cardImageUrl || ""}
                                        VideoUrl={item?.cardVideoUrl || ""}
                                        isFollower={isFollower}
                                        // setOpenModal={setOpenModal}
                                        setBuyerRequrestData={setBuyerRequrestData}
                                        OpenPopupforBuyCard={OpenPopupforBuyCard}
                                        responseData={responseData}
                                      />
                                    );
                                  }
                                })}
                              </SwiperBar>
                            );
                          }
                        )}
                      </div>
                    </div>
                  </React.Fragment>
                );
              })}
            </SummerCard>
          ) : (
            <div className="d-flex justify-content-center mt-5">
              {
                <p
                  style={{
                    color: "white",
                  }}
                >
                  {" "}
                  {!isLoading && "Data Not Found"}{" "}
                </p>
              }
            </div>
          )}
        </>
      ) : (
        <>
          {collectionValue == "none" ? (
            <GalleryType
              className="d-flex galleryRow"
              style={{
                width: `${window.screen.width > 787 ? "800px" : "100%"}`,
              }}
            >
              {collectionType?.map((data: any, index: number) => {
                return (
                  <div
                    className="galleryCol"
                    onClick={() => {
                      // setCollectionValue(data?.albumName);
                      getCardDetails(data?.albumName);
                    }}
                    key={index}
                    style={{
                      width: "380px",
                      overflow: "hidden",
                      height: "108px",
                      borderRadius: "10px",
                    }}
                  >
                    {data?.albumVideoUrl ? (
                      <img
                        src={data?.albumVideoUrl}
                        width={"100%"}
                        height={"100%"}
                      />
                    ) : (
                      <p style={{ color: "white" }}> {data?.albumName} </p>
                    )}
                  </div>
                );
              })}
            </GalleryType>
          ) : null}
        </>
      )}
      {displayMyCards &&
        Object.keys(myFilter)?.length > 0 &&
        totalPage >= curruntPage && (
          <>
            {!cardLoading ? (
              <p
                className="text-center"
                style={{
                  color: "#6352e8",
                  // border: "1px solid red",
                  cursor: "pointer",
                  fontSize: "15px",
                  padding: "10px",
                }}
                onClick={() => {
                  setCardLoading(true);
                  ShowMyCards();
                }}
              >
                {"View more"}
              </p>
            ) : (
              <div className="w-100 text-center">
                <span
                  className="loading "
                  style={{
                    color: "#fff",
                    fontSize: "15px",
                    padding: "10px",
                  }}
                >
                  {texts.waitForIt}
                </span>
              </div>
            )}
          </>
        )}
      {openModal == "buyercards" && (
        <BuyerPopup
          openModal={openModal}
          setOpenModal={setOpenModal}
          tradeCrad={createBuyRequrst}
          // leader={leader}
        />
      )}

      {openModal == "buyerDetails" && (
        <BuyerPopup
          openModal={openModal}
          setOpenModal={setOpenModal}
          tradeCrad={EditBuyerData}
          leader={buyerRequrestData}
        />
      )}      
    </div>
  );
};

export default Album;
