import React, { useContext, useEffect, useState } from 'react'
import VBG from '../../../assets/images/VBG.png';
import VBGM from '../../../assets/images/VBGM.png';
import UserContext from 'Contexts/User';
import { useNavigate } from 'react-router-dom';
import { H2 } from './VotingPaymentCopy';
import SmallBackArrow from '../../../Components/icons/SmallBackArrow';
import { ButttonDiv } from '../VotingBooster';
import {SendTransactionRequest, useTonConnectUI, useTonWallet} from "@tonconnect/ui-react";
import { auth, db, firestore } from "../../../firebase";
import axios from 'axios';
import AppContext from 'Contexts/AppContext';
import { ToastType } from 'Contexts/Notification';
import { showToast } from 'App';
import {Cell} from 'ton'

type TonWalletPropes = {
  payamount:any
  VTEAmount:any
  payType:any
  extraVote:any
  votePackageId:any
  setIsLoading:any
  setPaymentStatus:any
  setShowText:any
  setPayButton:any 
};
function TONWallet({
  payamount,
  VTEAmount,
  payType,
  extraVote,
  votePackageId,
  setIsLoading,
  setPaymentStatus,
  setShowText,
  setPayButton,  }:TonWalletPropes) {
  const { user, userInfo } = useContext(UserContext); 
 const wallet = useTonWallet();  
 const [tonConnectUi] = useTonConnectUI();  
 const [tonAmount, setTonAmount] = useState(0);
 const {getSpinData } = useContext(AppContext);

 const fetchTonPrice = async () => {
  try {
    const response = await axios.get(
      'https://api.coingecko.com/api/v3/simple/price?ids=the-open-network&vs_currencies=usd'
    );
    const price = response.data['the-open-network'].usd;
    console.log(price,"Tonprice")
    setTonAmount(price);
    return price
  } catch (error) {
    console.error('Error fetching TON price:', error);
  }  
};


// Fetch TON price when the component is mounted
useEffect(() => {
  fetchTonPrice();
}, []);



 const payNow = (detail?: any) => {

  const headers = {
    // 'Content-Type': 'application/json',
    "accept": "application/json",
    // @ts-ignore
    "Authorization": `Bearer ${auth?.currentUser?.accessToken}`,
    "content-type": "application/json"
  }  
  const data = {
    userId: `${user?.uid}`,
    userEmail: `${user?.email}`,
    walletType: `wallet connect`,
    amount: Number(payamount && Number(payamount)) / (tonAmount || 5),
    dollarAmount: Number(payamount),
    VTEAmount: Number(VTEAmount),        
    network: `${wallet?.account?.chain || -239}`,    
    origincurrency: `TON`,
    token: `TON`,
    event: 'Approved',
    transactionType: payType,
    numberOfVotes: extraVote,
    paymentDetails: detail,
    votePackageId:votePackageId,
  }
  console.log("afterPayment Data : ", data)
  axios.post(`${process.env.REACT_APP_API}/payment/update/user/afterPayment`, data,
    {
      headers: headers
    }).then(async (response) => {
      // setApiCalling(false)
      // console.log(,"response.data.data")
      console.log(response.data, "response.data")
      // transactionId.current = response.data
      setIsLoading(false)
      setPaymentStatus({ type: "success", message: "" })
      setShowText(false)
      setPayButton(false);
      getSpinData(userInfo?.uid)
      // setShowForWait(true)
    })
    .catch((error) => {
      // setPaymentStatus({ type: 'error', message: '' });
      console.log(error, "response.data")
      setIsLoading(false)
      setPaymentStatus({ type: "error", message: "We apologize for the inconvenience. Please ensure that you have a stable internet connection and try again." })
      setShowText(false)
      setPayButton(false);
    })
}

const sendTon = async (network:any) => {
  // setIsLoading(true) 
  const getPrice= await fetchTonPrice()    
  console.log(((payamount/getPrice) * 1e9),"payAmountValue")
if(network == "live"){
  if(wallet !== null && wallet?.account?.chain == "-239"){PayWithTon(getPrice)}
  else{
    showToast("You can not do payment with test net you need to connect with real wallet", ToastType.ERROR)  
  }
}else{PayWithTon(getPrice)}
}

const PayWithTon = async(tonPrice:number)=>{       
  try {      
    const transactionResponse = await tonConnectUi.sendTransaction({
      validUntil: Date.now() + 300000, // Transaction valid for 5 minutes
      messages: [
        {
        // The receiver's address.
          address: `${process.env.REACT_APP_TON_ACCOUNT_KEY}`,
          amount: `${Math.floor((payamount/tonPrice) * 1e9)}`,
          // amount: `385356454`,          
        },
      ],
    });
  
    const boc = transactionResponse.boc;
    // console.log(boc,"bocboc")
    const cell = Cell.fromBoc(Buffer.from(boc, 'base64'))[0];
      const transactionId = cell.hash().toString('hex'); // Transaction ID as hex
    if (boc) {
      setIsLoading(true)
      payNow({ hash:transactionId, orderId: `VTE-TON-${transactionId?.substring(0, 4)}` })
    }   
  
  } catch (error) {
    setPaymentStatus({ type: "error", message: "Couldn't Complete Transaction, Please try again." })
    console.error('Error decoding BOC:', error);
    setIsLoading(false);
  }
}


  return (
    <>
    <div>
      {wallet ?
              <div
        className={`${window.screen.width > 767 ? "" : "mt-3"} d-flex justify-content-center`}
      >
        <ButttonDiv className="mt-1">
          <button                        
            onClick={() => {
              sendTon(process.env.REACT_APP_SITE_URL?.includes("staging")?"staging":"live")           
            }}
          >

            PAY NOW!
          </button>
        </ButttonDiv>
      </div > 
      :
      <div
      className={`${window.screen.width > 767 ? "" : "mt-3"} d-flex justify-content-center`}
    >
      <ButttonDiv className="mt-1">
        <button           
          onClick={() => {
            tonConnectUi.openModal()          
          }}
        >
           CONNECT WALLET
        </button>
      </ButttonDiv>
    </div >
      }

      {wallet !== null && wallet?.account?.chain !== "-239" && <div className='text-center mt-2'
      onClick={()=>{
        tonConnectUi.disconnect().then((result) => {
          tonConnectUi.openModal()          
        }).catch((err) => {
          
        });
      }}
      >
          <p>Connect With real wallet</p>
      </div>}
    </div>
    </>
  )
}

export default TONWallet
