import React, { useContext, useEffect, useRef, useState } from 'react'
import lottie from 'lottie-web';
import SpinHeader from '../assets/animation/SpinHeader.json';
import styled from 'styled-components';
import AppContext from 'Contexts/AppContext';
// import GoldenSpinHeader from '../assets/animation/GoldenSpinHeader.json';
const SpinDiv = styled.div`
position:relative;
    & span{
    position: absolute;
    color: white;
    width: 10px;
    height: 10px;
    top: -3px;
    z-index: 10;
    font-size: 7px;
    background-color: red;
    border-radius: 50%;
    text-align: center;

    }
`;

function SpinerHeader() {
    const container = useRef(null);
    const [anim, setAnim] = useState<any>(null);
    const {freeSpinData, } = useContext(AppContext);

    useEffect(() => {
        if (container.current) {
            const animation = lottie.loadAnimation({
                container: container.current,
                animationData: SpinHeader,
                loop: true,
                autoplay: false, // Set autoplay to false to control manually
            });
            setAnim(animation);
        }

        return () => {
            if (anim) {
                anim.destroy();
            }
        };
    }, []);

    useEffect(() => {
        if (anim) {
            const playAndStopAnimation = () => {
                anim.play();
                setTimeout(() => {
                    anim.stop();
                    setTimeout(playAndStopAnimation, 30000); // Schedule next play after 30 seconds
                }, 3000); // Animation duration
            };

            playAndStopAnimation(); // Start the cycle

            return () => {
                anim.stop();
            };
        }
    }, [anim]);

    return (
        <SpinDiv>
            {freeSpinData.spinnerEnabled != false && freeSpinData?.SpinnerEnabled !=false && freeSpinData?.freeSpinAvailable && <span                
                style={{
                    right: window.screen.width > 767 ? "1px" : "1px",
                }}
            >1</span>}
            <div ref={container}></div>
        </SpinDiv>    
    );
}

export default SpinerHeader;


