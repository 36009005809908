import React, { useContext, useEffect, useRef, useState } from "react";
import * as d3 from "d3";
import "./wheel.css";
import useSound from "use-sound";
import lottie from "lottie-web";
import Confetti from "../../assets/animation/confetti.json";
// @ts-ignore
import claimSound from "../../assets/sounds/claimReward.m4a";
// @ts-ignore
import spinnerSound from "../../assets/sounds/spinnerfree.mp3";
// @ts-ignore
import winSound from "../../assets/sounds/WinCmp.mp3";
import celebrationIcon from "../../assets/images/coin_vet_won.png";
import confetti from "../../assets/animation/confetti.json";
import CountUp from "react-countup";
import UserContext from "Contexts/User";
import axios from "axios";
import AppContext from "Contexts/AppContext";
import { useNavigate } from "react-router-dom";
import { showToast } from "App";
import { ToastType } from "Contexts/Notification";
import { texts } from "Components/LoginComponent/texts";
import styled from "styled-components";
import { ButttonDiv, ButttonDivMob } from "Components/Profile/VotingBooster";
import WaitForResponse from "Components/Profile/CardTrade/WaitForResponse";

const SpinButton = styled.div`
  border:4px solid white;
  border-radius: 10%;
  
  button{
  border:2px solid black;
  border-radius: 10%;
  font-size:17px;
  padding:10px 40px;
  background: rgb(191,255,22);
background: linear-gradient(0deg, rgba(191,255,22,1) 0%, rgba(89,219,1,1) 16%, rgba(97,222,3,1) 72%, rgba(191,255,22,1) 96%);
  font-size: 20px;
  font-weight: bold;
  color: #fff;      
  text-shadow: 1px 3px 3px rgba(87, 85, 84, 1);                                                                 
  }
`;

interface DataItem {
  label: string;
  value: number;
  xp: string;
}
interface WheelProps {
  handleShowPopUp: any;
}

const Wheel = ({handleShowPopUp}: WheelProps) => {  
  const { getSpinData, megaSpinData, setMegaSpinData, freeSpinData, setFreeSpinData, contDownValue, setContDownValue } = useContext(AppContext);  
  const [playSpinnerSound, { pause: pauseSpinnerSound }] =
    useSound(spinnerSound);
  const [isLoading , setIsLoading ] = useState(false)
  const [show, setShow] = useState<any>(false);
  const [winVote, setWinVote] = useState<any>(0);
  let navigate = useNavigate();

  var urlName = window.location.pathname.split('/');
  const ProfileUrl = urlName.includes("wheel")

  // const handleClose = () => {
  //   const rotatedDiv = document.querySelector(".wheeldotsround") as HTMLElement;  
  //   getSpinValue(winVote);  
  //   getSpinData(userInfo?.uid)
  //   rotatedDiv.style.transform = `rotate(${0}deg)`;
  //   // navigate("/streak")        
  //   setShow(false)
  //   setTimeout(() => {
  //     if (ProfileUrl && freeSpinData.totalSpinsUsed > 4) {
  //       let Free = freeSpinData?.freeSpinAvailable == 0 ? 0 : freeSpinData?.freeSpinAvailable - 1
  //       let mega = megaSpinData?.megaSpinAvailable == 0 ? 0 : megaSpinData?.megaSpinAvailable 
  //       handleShowPopUp(Free,mega)
  //     }
  //   }, 10000);
  // };


  const handleClose = async () => {
    setShow(false)
    const rotatedDiv = document.querySelector(".wheeldotsround") as HTMLElement;
    const element = document.getElementById("app");
    rotatedDiv.style.transform = `rotate(${0}deg)`;
    setIsLoading(true)
    try {
      const data = {
        userId: userInfo?.uid,
        votes: winVote,
        spinType: "FREE"
      }
      const getSpinVote = await axios.post(`/luckySpin/updateDataOnSpinning`, data).then((result) => {
      }).catch((err) => {
        console.log(err)
        showToast(err?.response?.data?.message, ToastType.ERROR)
      });

      const getSpinData = await axios.get(`/luckySpin/updateFreeSpinAvailable/${userInfo?.uid}`).then((response) => {
        let data = response?.data?.result;
        if (data?.freeSpinData) {
          setFreeSpinData(data?.freeSpinData);
          if (data?.freeSpinData?.freeSpinAvailable < 1) {
            const date = new Date(
              data?.freeSpinData?.lastSpinTime._seconds * 1000
            );
            // const endTime = date.getTime() + 1 * 60 * 60 * 1000;
            const endTime = date.getTime() + 4 * 60 * 60 * 1000;
            setContDownValue(endTime);
          }
        }
        if (data?.megaSpinData) {
          setMegaSpinData(data?.megaSpinData);
        }                        
        setIsLoading(false)
        setTimeout(() => {
          if (ProfileUrl && data?.freeSpinData.totalSpinsUsed > 4) {
            let Free = data?.freeSpinData?.freeSpinAvailable == 0 ? 0 : data?.freeSpinData?.freeSpinAvailable
            let mega = data?.megaSpinData?.megaSpinAvailable == 0 ? 0 : data?.megaSpinData?.megaSpinAvailable
            handleShowPopUp(Free, mega)
          }
        }, 10000);
        if (element) {
          // Set pointer-events to none
          element.style.pointerEvents = "auto";
        }
      }).catch((err) => {
        console.log(err)
        showToast(err?.response?.data?.message, ToastType.ERROR)
      });
    } catch (error) {
      console.log(error)
      setIsLoading(false)
      if (element) {
        element.style.pointerEvents = "auto";
      }
    }
  }

  const handleShow = () => setShow(true);
  const [streakData, setStreakData] = useState<any>({});
  const [onAddClass, setOnAddClass] = useState<any>("");
  const [spinObj, setSpinObj] = useState<any>([
    { label: "3", value: 3, xp: "you Win 3" },
    { label: "7", value: 7, xp: "you Win 7" },
    { label: "5", value: 5, xp: "you Win 5" },
    { label: "9", value: 9, xp: "you Win 9" },
    { label: "3", value: 3, xp: "you Win 3" },
    { label: "6", value: 6, xp: "you Win 7" },
    { label: "5", value: 5, xp: "you Win 5" },
    { label: "12", value: 12, xp: "you Win 12" },
  ]);
  const spinbuttonClick = useRef<HTMLButtonElement>(null);

  console.log(spinObj,"spinObjValue")
  const { userInfo } = useContext(UserContext);

  const schemeCategory10 = [
    "#1f77b4",
    "#ff7f0e",
    "#2ca02c",
    "#d62728",
    "#9467bd",
    "#8c564b",
    "#e377c2",
    "#7f7f7f",
    "#bcbd22",
    "#17becf",
  ];
  const popupSound = () => {
    try {
      //@ts-ignore
      document.getElementById("audio-Cpp-popup")?.play();
      // playSpinnerSound();
      console.log("playSpeenSound ============>");
    } catch (error) {
      console.log("playSpeenSound catch", error);
    }
  };
  const playSpeenSound = () => {
    try {
      //@ts-ignore
      document.getElementById("audio-Cpp")?.play();
      // playSpinnerSound();
      console.log("playSpeenSound ============>");
    } catch (error) {
      console.log("playSpeenSound catch", error);
    }
  };
  const pauseSpeenSound = () => {
    try {
      //@ts-ignore
      document.getElementById("audio-Cpp")?.pause();
      // pauseSpinnerSound();
      console.log("playSpeenSound Pause ============>");
    } catch (error) {
      console.log("playSpeenSound pause catch", error);
    }
  };

  useEffect(() => {    
    setIsLoading(true);
    if (userInfo?.uid) {   
      getSpinData(userInfo?.uid)
      axios
        .get(`/luckySpin/getFreeAndMegaSpinVotes/${userInfo?.uid}/FREE`)
        .then((res) => {                   
          if (res.status == 200) {
            setStreakData(res.data.result);
            const updatedArray = res?.data?.result?.votesToShowOnSpinner?.map(
              (value: any) => ({
                label: `${value}`,
                value: value,
                xp: `you Win ${value}`,
              })
            );  
            setIsLoading(false); 
            setSpinObj(updatedArray);
          }
        })
        .catch((error) => {  
          console.log("response ", error.response.data.message);
          setIsLoading(false);
        });
    }
  }, [userInfo?.uid]);

  useEffect(() => {
    const Animation = lottie.loadAnimation({
      // @ts-ignore
      // container: document.querySelector("#card-animation"),
      container: document.querySelector("#card-animation"),
      animationData: confetti,
      renderer: "html", // "canvas", "html"
      loop: true, // boolean
      autoplay: true, // boolean
    });
  });

  useEffect(() => {
      const padding = { top: 0, right: 0, bottom: 0, left: 0 };
      const w = 400 - padding.left - padding.right;
      const h = 400 - padding.top - padding.bottom;
      const r = Math.min(w, h) / 2;
      let rotation = 0;
      let oldrotation = 0;
      let picked = 200000;
      let oldpick: number[] = [];
      const color = d3.scaleOrdinal(d3.schemeCategory10);
      const data: DataItem[] = spinObj;
      const maxSpins = data.length;

      var spinwheelDiv = document.getElementById("spinwheel");

      if (spinwheelDiv) {
        var firstSvg = spinwheelDiv.querySelector("svg");
        if (firstSvg) {
          spinwheelDiv.removeChild(firstSvg);
        }
      }

      const svg = d3
        .select("#spinwheel")
        .append("svg")
        .data([data])
        .attr("xmlns", "http://www.w3.org/2000/svg")
        .attr("viewBox", `0 0 ${w} ${w}`)
        .attr("width", w)
        .attr("height", h + padding.top + padding.bottom);

      const container = svg
        .append("g")
        .attr("class", "chartholder")
        .attr(
          "transform",
          `translate(${w / 2 + padding.left}, ${h / 2 + padding.top})`
        );

      const vis = container.append("g");

      const pie = d3
        .pie<DataItem>()
        .sort(null)
        .value(() => 1);

      const arc = d3.arc().innerRadius(0).outerRadius(300);

      const arcs = vis
        .selectAll("g.slice")
        .data(pie(data))
        .enter()
        .append("g")
        .attr("class", "slice");

      arcs
        .append("path")
        .attr("fill", (d: any, i: any) => color(i.toString())!)
      .attr("d", (d: any) => arc(d) as string);    
    
    arcs
      .append("text")          
      .attr("transform", (d: any) => {
          const angle = (d.startAngle + d.endAngle) / 2;
          const rotate = (angle * 180) / Math.PI - 90; // Rotate to align text with the arc
          const flip = rotate > 90 || rotate < -90 ? 180 : 0; // Flip text for better readability
          const translate = 130; // Adjust the translation to position text closer to the edge
          return `rotate(${rotate}) translate(${translate}, 0) rotate(${90})`;
        })
        // .attr("font-size", "35")
        .attr("fill", "#ffffff")
        .attr("font-weight", "bold")        
        .attr("line-height", "initial")
        .attr("font-weight", "600")
        .attr("text-anchor", "middle")                        
        .attr("class", "wheel-text123")                
        .text((d: any, i: any) => data[i].label);
        const resetOldPick = () => {
          oldpick = [];
        };      
          
    const spin = async () => {
        // @ts-ignore
      let spinButton = null;
      
      document.querySelectorAll(".SpinClick").forEach(element => {
      spinButton = element;
      });
      if (freeSpinData.spinnerEnabled == false) return
        if (!spinButton) return;
      // @ts-ignore
        spinButton.removeEventListener("click", spin);

        const ps = 360 / data.length;
        const rng = Math.floor(Math.random() * 15000 + 360);

        rotation = Math.round(rng / ps) * ps;
        picked = Math.round(data.length - (rotation % 360) / ps) + 2; 
        picked = picked >= data.length ? picked % data.length : picked;      
        if (oldpick.indexOf(picked) !== -1) {
          if (oldpick.length === maxSpins) {
            // All segments have been picked, reset the oldpick array for a fresh spin cycle
            resetOldPick();
          }
          spin();
          return;
        } else {
          oldpick.push(picked);
        }
        

        rotation += 295 - Math.round(ps / 1);      
      const rotatedDiv = document.querySelector(".wheeldotsround") as HTMLElement; 
        vis
          .transition()
          .duration(12000) // 5 seconds for the spinning animation        
          .ease(d3.easeCubicOut) // Linear easing for constant speed
          .attrTween("transform", () => {
            const i = d3.interpolate(oldrotation % 360, rotation);
            return (t: number) => {
              const currentRotation = i(t);               
              rotatedDiv.style.transform = `rotate(${currentRotation}deg)`;
              return `rotate(${currentRotation})`; // Apply to vis
            };
          })
          .on("start", async () => {
            try {
              playSpeenSound(); // Function to play spin sound
              const element = document.getElementById("app");

              if (element) {
                // Set pointer-events to none
                element.style.pointerEvents = "none";
              }
            } catch (error) {
              console.log("startmusic error", error);
            }
          })
          .on("end", () => {
            // clearInterval(interval);
            d3.select(`.slice:nth-child(${picked + 1}) path`);            
            handleShow();
            setWinVote(data[picked]?.value)
            popupSound()
            oldrotation = rotation;
            pauseSpeenSound(); // Function to pause spin sound
            // @ts-ignore
            spinButton.addEventListener("click", spin);
          });
      };

    document.querySelectorAll(".SpinClick").forEach(element => {
      element.addEventListener("click", spin);
    });
    return () => {
      document.querySelectorAll(".SpinClick").forEach(element => {
        element.removeEventListener("click", spin);
      });
    };
  
  }, [spinObj]); // Empty dependency array to ensure useEffect runs only once

  // const getSpinValue = (voteValue: any) => {
  //   getSpinData(userInfo?.uid)
  //   setIsLoading(true)
  //   const element = document.getElementById("app");
  //   const data = {
  //     userId: userInfo?.uid,
  //     votes: voteValue,
  //     spinType: "FREE"
  //   }
  //   axios
  //     .post(`/luckySpin/updateDataOnSpinning`, data)
  //    .then((res) => {
  //       if (res.status == 200) {
  //         // setSpinValue(res.data.result.spinValue);                    
          
  //         setIsLoading(false);
  //         if (element) {
  //           // Set pointer-events to none
  //           element.style.pointerEvents = "auto";
  //         }
  //       }       
  //     })
  //    .catch((error) => {
  //      console.log("response ", error.response.data.message);
  //      setIsLoading(false);
  //      if (element) {
  //        // Set pointer-events to none
  //        element.style.pointerEvents = "auto";
  //      }       
  //      showToast(error?.response?.data?.message, ToastType.ERROR);       
  //      //  navigate("/votingbooster")            
  //     });
  // }


  return (
    <div className="gr_bg">  
    {isLoading && <WaitForResponse/>}
      {show && (
        <div
          className="modal fade show vote-result-model"
          style={{ display: "block" ,overflow: "hidden" }}
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div
                id="card-animation"
                style={{
                  height: "400px",
                  width: "400px",
                  position: "absolute",
                  top: "0px",
                  right: "0px",
                  left: "0px",
                  margin: "0px auto",
                }}
              />

              {/* <button
                type="button"
                className="btn-close"
                onClick={handleClose}
              ></button> */}

              <div className="modal-body pb-0 text-center b-0">
                {/* <h4>Congratulations!</h4> */}

                <svg width="" height="100" viewBox="0 0 500 200">
                  <path
                    id="curve"
                    fill="transparent"
                    d="M50,150 Q250,50 450,150"
                  />
                  <text className="curved-text">
                    <textPath
                      href="#curve"
                      startOffset="50%"
                      text-anchor="middle"
                    >
                      Congratulations!
                    </textPath>
                  </text>
                </svg>        
                <p className="mb-0 pt-3">You've Won {winVote} Votes</p>
              </div>
              <div className="modal-footer py-0 text-center justify-content-center mb-3">
                <button type="button" className="btn m-0" onClick={handleClose}>
                  Collect Your Votes
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="container text-center mb-3 pt-5">        
        <div className="wheel-spin-box2 unpaid-spin">
          <button
            id="SpinClick"
            ref={spinbuttonClick}
            className={`spin-click-button ${onAddClass}  SpinClick`}                       
            disabled={!freeSpinData?.freeSpinAvailable}          
          >
            Spin
          </button>

          <div id="spin-arrow" className="wheel-spin-arrow">
            <svg
              width="26"
              height="51"
              viewBox="0 0 26 51"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_1567_770)">
                <path
                  d="M13.3254 24.8453C13.1578 24.7093 13.0307 24.6088 12.9055 24.5033C9.9724 22.0216 7.03731 19.5419 4.10715 17.0552C3.60844 16.6324 3.07031 16.3525 2.39912 16.4944C1.13954 16.7625 0.583662 17.8811 1.04788 19.2008C3.41428 25.9255 5.78167 32.6502 8.14906 39.3749C9.2539 42.513 10.3627 45.6502 11.4577 48.7912C11.6982 49.4802 12.0619 50.0311 12.805 50.2312C13.8803 50.5219 14.7505 49.9937 15.1724 48.7962C17.3436 42.6273 19.5129 36.4575 21.6842 30.2887C22.9901 26.578 24.2989 22.8682 25.6039 19.1575C25.934 18.2192 25.7655 17.4563 25.1219 16.9153C24.3768 16.2894 23.4484 16.2933 22.651 16.9586C21.2771 18.1058 19.915 19.2679 18.549 20.424C16.8193 21.8866 15.0906 23.3502 13.3244 24.8443L13.3254 24.8453ZM13.3145 8.70134C12.8789 8.3347 12.4669 7.98778 12.055 7.63986C9.39681 5.39075 6.73769 3.14261 4.08251 0.889554C3.62618 0.502217 3.12944 0.240048 2.51345 0.333679C1.26076 0.522912 0.571836 1.63762 0.988741 2.84989C1.73385 5.02016 2.48881 7.18748 3.28418 9.34001C3.42906 9.73326 3.73065 10.1117 4.05294 10.3897C6.36908 12.3815 8.70887 14.3458 11.0418 16.319C11.7918 16.9537 12.5418 17.5874 13.2968 18.2241C13.3825 18.167 13.4387 18.1364 13.487 18.096C16.5847 15.4773 19.6864 12.8625 22.7723 10.229C23.0226 10.0151 23.2335 9.69975 23.3459 9.38929C24.1383 7.20029 24.909 5.00341 25.6679 2.80259C25.9577 1.96286 25.6778 1.15468 24.9879 0.666809C24.2231 0.12572 23.3794 0.197668 22.5574 0.891524C19.6332 3.36043 16.7129 5.83426 13.7916 8.30514C13.6437 8.43031 13.4929 8.55252 13.3155 8.70036L13.3145 8.70134Z"
                  fill="black"
                />
                <path
                  d="M13.3252 24.8453C15.0914 23.3512 16.8201 21.8876 18.5499 20.4249C19.9169 19.2688 21.278 18.1068 22.6519 16.9596C23.4492 16.2943 24.3777 16.2904 25.1228 16.9162C25.7664 17.4563 25.9349 18.2202 25.6047 19.1585C24.2998 22.8692 22.9909 26.579 21.685 30.2897C19.5138 36.4585 17.3445 42.6283 15.1732 48.7971C14.7514 49.9946 13.8811 50.5229 12.8058 50.2322C12.0627 50.0311 11.699 49.4811 11.4585 48.7922C10.3635 45.6511 9.25475 42.514 8.14991 39.3759C5.78252 32.6512 3.41513 25.9265 1.04873 19.2018C0.584514 17.8821 1.14039 16.7625 2.39997 16.4954C3.07116 16.3525 3.60929 16.6324 4.108 17.0562C7.03816 19.5428 9.97226 22.0226 12.9064 24.5043C13.0306 24.6098 13.1587 24.7103 13.3262 24.8463L13.3252 24.8453Z"
                  fill="#FFB11A"
                />
                <path
                  d="M13.3136 8.70133C13.491 8.5535 13.6418 8.43129 13.7896 8.30612C16.7119 5.83425 19.6322 3.36141 22.5555 0.892504C23.3774 0.197662 24.2211 0.126698 24.9859 0.667788C25.6758 1.15565 25.9557 1.96384 25.666 2.80356C24.9081 5.00438 24.1363 7.20127 23.3439 9.39027C23.2316 9.70073 23.0207 10.0161 22.7703 10.23C19.6844 12.8635 16.5828 15.4783 13.4851 18.097C13.4368 18.1374 13.3796 18.1679 13.2948 18.2251C12.5409 17.5874 11.7898 16.9537 11.0398 16.32C8.70692 14.3468 6.36713 12.3835 4.05099 10.3906C3.7287 10.1137 3.42711 9.73424 3.28223 9.34099C2.48685 7.18846 1.73189 5.02114 0.986788 2.85087C0.570869 1.63761 1.25881 0.522905 2.5115 0.334658C3.1265 0.242012 3.62324 0.503193 4.08056 0.89053C6.73574 3.14359 9.39486 5.39074 12.053 7.64084C12.464 7.98875 12.877 8.33568 13.3126 8.70232L13.3136 8.70133Z"
                  fill="#FFC75B"
                />
              </g>
              <defs>
                <clipPath id="clip0_1567_770">
                  <rect
                    width="24.9138"
                    height="50"
                    fill="white"
                    transform="matrix(-1 0 0 -1 25.7803 50.3101)"
                  />
                </clipPath>
              </defs>
            </svg>
          </div>

          <div id="spinwheel">
            <div className="wheeldotsround">
              <div className="rotated-div">
              {spinObj.map((item:any, index:number) => (
                // <div className="wheeldots" key={index}></div>
                <div className="rotated-text">votes</div>
              ))}
              </div>
            </div>
          </div>
        </div>
      </div>      
      <div className="d-flex justify-content-center">
        <ButttonDiv
          className="mt-2 SpinClick"
          id="SpinClick2"
          
        onClick={() => {
          // navigate("/streak");
          if (spinbuttonClick.current) {
            spinbuttonClick.current.dataset.clicked = 'true';
          }
        }}
      >
        <button>SPIN NOW</button>
        </ButttonDiv>       
      </div>
      <audio id="audio-Cpp" src={spinnerSound}></audio>
      <audio id="audio-Cpp-popup" src={claimSound}></audio>
      <div className="position-relative py-5">
        <div className="counter-container">
          <div className="counter-row-section">
            <div className="text-center">
              <h1>50% Daily Streak</h1>
              <h2>Come back tomorrow to maintain your streak!</h2>

              <div className="counter-row">
                <div className="ct-box">
                  <div className="radius">
                    <div
                      className={
                        [0, 1, "0", "1"].includes(streakData?.streakCount)
                          ? `ct-inner active`
                          : "ct-inner"
                      }
                    >
                      <div className="text-end-spin"
                        style={{
                          fontSize: "20px",
                          padding: "30px 0px",
                        }}
                      >Day 1</div>
                      <div className="ct-text"
                        style={{
                        color:"#6152c9"
                      }}
                      >
                        +<CountUp end={10} />%
                      </div>
                    </div>
                  </div>
                </div>
                {/* streakCount */}

                <div className="ct-box">
                  <div className="radius">
                    <div
                      className={
                        [2, "2"].includes(streakData?.streakCount)
                          ? `ct-inner active`
                          : "ct-inner"
                      }
                    >
                      <div className="text-end-spin">Day 2</div>
                      <div className="ct-text">
                        +<CountUp end={50} />%
                      </div>
                    </div>
                  </div>
                </div>

                <div className="ct-box">
                  <div className="radius">
                    <div
                      className={
                        [3, "3"].includes(streakData?.streakCount)
                          ? `ct-inner active`
                          : "ct-inner"
                      }
                    >
                      <div className="text-end-spin">Day 3</div>
                      <div className="ct-text">
                        +<CountUp end={100} />%
                      </div>
                    </div>
                  </div>
                </div>

                <div className="ct-box">
                  <div className="radius">
                    <div
                      className={
                        [4, "4"].includes(streakData?.streakCount)
                          ? `ct-inner active`
                          : "ct-inner"
                      }
                    >
                      <div className="text-end-spin">Day 4</div>
                      <div className="ct-text">
                        +<CountUp end={150} />%
                      </div>
                    </div>
                  </div>
                </div>

                <div className="ct-box">
                  <div className="radius">
                    <div
                      className={
                       ([5, "5"].includes(streakData?.streakCount) || streakData?.streakCount >= 5)
                          ? `ct-inner active`
                          : "ct-inner"
                      }
                    >
                      <div className="text-end-spin">Day 5+</div>
                      <div className="ct-text">
                        +<CountUp end={200} />%
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="counter-result-section d-none">
              <h3>Streak Bonus</h3>
              400%
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Wheel;
