import { VoteContextType } from "Contexts/VoteProvider";
import axios from "axios";
import { NotificationProps, UserProps } from "common/models/User";
import { VoteResultProps } from "common/models/Vote";
import { db, auth } from "../../firebase";
import { collection, doc, getDoc, getDocs, limit, onSnapshot, orderBy, query, setDoc, where } from "firebase/firestore";
import { type } from "os";
import React from "react";
import Swal from 'sweetalert2';
import moment from "moment";
import { showToast } from "App";
import { ToastType } from "Contexts/Notification";

export type calculateDiffBetweenCoinsType = { firstCoin: string, secondCoin: string, difference: string };
export const calculateDiffBetweenCoins = (valueVotingTime: number[], valueExpirationTime: number[], direction: number) => {

    const firstCoin = (((valueExpirationTime[0] - valueVotingTime[0]) * 100) / valueVotingTime[0]);
    const secondCoin = (((valueExpirationTime[1] - valueVotingTime[1]) * 100) / valueVotingTime[1]);
    const difference = (direction === 0 ? (firstCoin) - (secondCoin) : (secondCoin) - (firstCoin))
    return {
        firstCoin: firstCoin.toFixed(4) || '0',
        secondCoin: secondCoin.toFixed(4) || '0',
        // @ts-ignore
        difference: difference != undefined && difference != "" && difference != "NaN" ? difference : '0'
    }
}

export const getCoinDifferenceColor = (value: number) => {
    return value < 0 ? '#fa0202' : value > 0 ? 'green' : '#050505'
}
export const getPairResultColor = (coin1Percentage: number, coin2Percentage: number, direction: number) => {
    if (direction) {
        return coin2Percentage > coin1Percentage ? 'green' : (coin1Percentage > coin2Percentage ? '#fa0202' : '#050505');
    } else {
        return coin1Percentage > coin2Percentage ? 'green' : (coin2Percentage > coin1Percentage ? '#fa0202' : '#050505');
    }
}


export const getCoinColor = (currentValue: number, prevValue: number) => {
    return currentValue < prevValue ? '#fa0202' : currentValue > prevValue ? 'green' : '#050505'
}
export const getSingleCoinPriceColor = (votingPrice: number, currentPrice: number, direction: number) => {
    //0 bull,1 bear , 1 price will increase 

    if (direction) {
        return votingPrice > currentPrice ? 'green' : votingPrice < currentPrice ? '#fa0202' : '#050505'
    } else {
        return votingPrice < currentPrice ? 'green' : votingPrice > currentPrice ? '#fa0202' : '#050505'
    }
}

export const scrollUp = () => {
    setTimeout(() => {
        window.scrollTo({
            top: 300,
            behavior: "smooth",
        });
    }, 2000);
}

export type cardDetailType = {
    id: string;
    cardName: string;
    cardId: string;
    quantity: number;
    albumId: string;
    cardStatus: string;
    totalQuantity: number;
    noOfCardHolders: number,
    cardImageUrl: string;
    cardVideoUrl: string;
    albumName: string;
    setId: string;
    cardType: string;
    setName: string;
    sno: string[]
}

export const divideArray = (arr: any, partSize: any) => {

    let divideByAlbum: { [key: string]: cardDetailType[] } = {};
    arr.map((value: any) => {
        divideByAlbum = { ...divideByAlbum, [value?.albumName]: (divideByAlbum[value?.albumName] ? [...divideByAlbum[value?.albumName], value] : [value]) }
    });
    let res1: cardDetailType[][] = [];
    Object.keys(divideByAlbum).map((albumName: string) => {
        let album = divideByAlbum[albumName];
        for (let i = 0; i < album.length; i += partSize) {
            res1 = [...res1, album.slice(i, i + partSize)];
        }
    });
    return res1;

}
export const divideArray1 = (arr: any, partSize: any) => {

    let divideByAlbum: { [key: string]: cardDetailType[] } = {};
    arr.map((value: any) => {
        divideByAlbum = { ...divideByAlbum, [value?.albumName]: (divideByAlbum[value?.albumName] ? [...divideByAlbum[value?.albumName], value] : [value]) }
    });
    let res1: { [key: string]: cardDetailType[][] } = {};
    Object.keys(divideByAlbum).map((albumName: string) => {
        let album = divideByAlbum[albumName];
        let albumWithSlice: cardDetailType[][] = [];
        for (let i = 0; i < album.length; i += partSize) {
            albumWithSlice = [...albumWithSlice, album.slice(i, i + partSize)];
        }
        res1 = { ...res1, [albumName]: albumWithSlice };
    });
    console.log(res1, 'divideByAlbum');
    return res1;

}

export const getPaginationData = (data: any[], pageIndex = 0, pageSize = 5) => {
    // page index start from 0
    return data.slice(pageSize * pageIndex, pageSize * (pageIndex + 1))
}

export const afterpaxDistributionToUser = (pax:number) => {
    axios.post("https://us-central1-votetoearn-9d9dd.cloudfunctions.net/updateAndGetPaxDistribution", {
        data:pax
    }).then((res) => {
        console.log(res.data,"afterpaxDistributionToUser")
    }).catch((err) => {
        console.log(err,"afterpaxDistributionToUser")        
    })
    
}


export function ReloadPop() {
    Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong! Please reload your page",
        confirmButtonText: "Reload page",
        allowOutsideClick: false
        // footer: '<a href="#">Why do I have this issue?</a>'
    }).then((result) => {
        if (result.isConfirmed) { 
            window.location.reload();
        }        
     });
}

export const UpdateLastLogin = async (user?:any) => {
    const lastLoginDay = new Date();
    const userRefUpdate = doc(db, "userStatistics",user?.uid);
    await setDoc(userRefUpdate, { lastLoginDay }, { merge: true });
}



export const getAllNotifications = async (userId: any, setNotifications:any, limitNumber:number) => {    
    const q = query(
        collection(db, 'notifications'),
        where('user', '==', userId || ''),
        orderBy('time', 'desc'),
        limit(limitNumber)
    );
    
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const data:any = [];
        // setNotificationCount((prev:any) => prev + 1)
        querySnapshot.forEach((doc) => {
            data.push({ ...doc.data() });
        });        
        setNotifications(data as NotificationProps);   
    });    
    return unsubscribe;
};

export const GetVoteDetelis = async (voteId:any) => {
    console.log(voteId,"voteIdvoteId")    
const docRef = doc(db, "votes", voteId);    
try {
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      const { multiplier } = docSnap.data();      
      return multiplier;
    } else {
      console.log('No such document!');
      return null;
    }
  } catch (error) {
    console.error("Error fetching document: ", error);
  }
};

export const cardTradNotificationsCount = async (userId: any, setNotificationCount: any, limitNumber:number) => {    
    const q = query(
        collection(db, 'notifications'),
        where('user', '==', userId || ''),
        where('notificationSeen', '==', false),
        // orderBy('time', 'desc'),
        limit(limitNumber)
    );
    
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const data:any = [];
        querySnapshot.forEach((doc) => {
            data.push({ ...doc.data() });
        });        
        setNotificationCount(data?.length)
        // setNotifications(data as NotificationProps);   
    });    
    return unsubscribe;
};



export const fetchCurrentTime = async()=> {
        try {
            const response = await fetch('http://worldtimeapi.org/api/timezone/Etc/UTC');
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            console.log(data,"getTimezone")
            const realtime = new Date(data.utc_datetime).getTime();        
            console.log(realtime,"getTimezonerealtime")
        } catch (error) {
            console.error('Failed to fetch the current time:', error);
            return Date.now(); // Fallback to local time
        }
};


export const getPriceFirstTime = async (coins:any, setCoins:any)=> {
    console.log('Attempting to reconnect...');
    // @ts-ignore
    if (coins) {                
        const coinTikerList = Object.keys(coins)?.map(item => `${item}`)
        const afterErrorPrice = {}
        try {
            const response = await axios.get(`https://min-api.cryptocompare.com/data/pricemulti?fsyms=${coinTikerList.join(',')}&tsyms=USD`);
            Object.keys(response.data)?.map((symblaName) => {
                Object.assign(afterErrorPrice,
                    {
                        [symblaName]: {
                            id: coins[symblaName]?.id,
                            name: coins[symblaName]?.name,
                            price: response.data[symblaName]?.USD,
                            symbol: symblaName,
                            trend: coins[symblaName]?.trend

                        }
                    }
                )
                setCoins(afterErrorPrice)
                localStorage.setItem('CoinsPrice', JSON.stringify(afterErrorPrice));
                console.log(afterErrorPrice,"afterErrorPrice")                
            })
            return afterErrorPrice
        } catch (error) {
            console.error('Error fetching data:', error);
            localStorage.setItem('CoinsPrice', JSON.stringify(coins));
        }
    }    
}
const getUniqueRandomValues=()=> {
    const chars = 'abcdefghijklmnopqrstuvwxyz0123456789';
    let values = '';

    while (values.length < 4) {
        const randomChar = chars.charAt(Math.floor(Math.random() * chars.length));
        if (!values.includes(randomChar)) {
            values += randomChar;
        }
    }

    return values;
}

export const payWithVTE = (user: any, payamount: any, isLoading: any, getSpinData: any, setIsLoading: any) => {
    setIsLoading(true);
    const headers = {        
        "accept": "application/json",
        // @ts-ignore
        "Authorization": `Bearer ${auth?.currentUser?.accessToken}`,
        "content-type": "application/json"
    }    
    const data = {
        userId: `${user?.uid}`,
        userEmail: `${user?.email}`,
        walletType: `wallet connect`,
        amount: Number(0),
        dollarAmount: Number(0),
        VTEAmount: Number(payamount),
        // amount: 0.0001,
        // @ts-ignore
        network: "",
        // @ts-ignore
        origincurrency: "",
        token:"VTE",
        event: 'Approved',
        transactionType:"MEGASPIN",
        numberOfVotes: 0,
        paymentDetails: {
            orderId: `VTE-ME-${getUniqueRandomValues()}`,
        },
        

    }    
    axios.post(`${process.env.REACT_APP_API}/payment/update/user/afterPayment`, data,
        {
            headers: headers
        }).then((result) => {
            setIsLoading(false);
            getSpinData(user?.uid)
        }).catch((err) => {
            setIsLoading(false);   
            showToast((err as Error).message, ToastType.ERROR);
        });
}


export const getUserNameDB = async (userId: any) => {
    const q = query(
        collection(db, 'users'),
        where('uid', '==', userId),
        limit(1)
    );
    const usersSnapshot = await getDocs(q);
    const existingUsernames = usersSnapshot.docs.map((doc:any) => doc.data());
    const isUsernameTaken = await existingUsernames[0].userName;
    return isUsernameTaken

};

export const getUserData = async (userId: any) => {
    const q = query(
        collection(db, 'users'),
        where('uid', '==', userId),
        limit(1)
    );
    const usersSnapshot = await getDocs(q);
    const existingUsernames = usersSnapshot.docs.map((doc:any) => doc.data());
    const isUsernameTaken = await existingUsernames[0]
    return isUsernameTaken

};

export const convertTime = (timestamp: any) => {
    const milliseconds = (timestamp._seconds * 1000) + (timestamp._nanoseconds / 1000000);
    const date = moment(milliseconds);
    return date.format('YYYY-MM-DD HH:mm:ss'); // Adjust format as needed
}
export const BuySpin = async () => {
    return await Swal.fire({
        title: 'Buy Mega Spin',
        text: 'Are you sure?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#6352e8',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes',
        cancelButtonText: 'No'
    });
};

export const telegramLoginData = async (queryString:any) => {
    let params = new URLSearchParams(queryString);

    // Decode and parse 'user' parameter
    let userEncoded = params.get('user');
    // @ts-ignore
    let userDecoded = JSON.parse(decodeURIComponent(userEncoded));

    // Extract other needed parameters
    let authDate = params.get('auth_date');
    let hash = params.get('hash');

    // Construct the object
    let result = {
        id: userDecoded.id,
        first_name: userDecoded.first_name,
        secret: process.env.REACT_APP_TELEGRAM_LOGIN_SECRET_KEY,
        auth_date: Number(authDate),
        hash: hash,
    };
    return result
};
export const confirmDelete = async () => {
    return await Swal.fire({
        title: 'Cancel offer',
        text: 'Are you sure?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#6352e8',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes',
        cancelButtonText: 'No'
    });
};
export const confirmApproved = async () => {
    return await Swal.fire({
        title: 'Approve offer',        
        text: 'Are you sure?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#6352e8',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes',
        cancelButtonText: 'No'
    });
};
export const confirmReject = async () => {
    return await Swal.fire({
        title: 'Reject offer',
        text: 'Are you sure?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#6352e8',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes',
        cancelButtonText: 'No'
    });
};

export const confirmApprovedGift = async () => {
    return await Swal.fire({
        title: 'Approve Gift',
        text: 'Are you sure?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#6352e8',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes',
        cancelButtonText: 'No'
    });
};
export const confirmRejectGift = async () => {
    return await Swal.fire({
        title: 'Reject Gift',
        text: 'Are you sure?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#6352e8',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes',
        cancelButtonText: 'No'
    });
};
export const confirmDeleteGift = async () => {
    return await Swal.fire({
        title: 'Cancel Gift',
        text: 'Are you sure?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#6352e8',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes',
        cancelButtonText: 'No'
    });
};

export const getCurrentDateTime = (timestampValue?: any) => {
    
    let now;
    if (timestampValue) {
        now = new Date(timestampValue);
    } else {
        now = new Date();
    }

    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0');
    const day = String(now.getDate()).padStart(2, '0');
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    return `${year}-${month}-${day}T${hours}:${minutes}`;
};


export const handleValidation = (event:any) => {
    const selectedDateTime = new Date(event.target.value);
    const currentDateTime = new Date();

    if (selectedDateTime < currentDateTime) {
        alert('You cannot select a past date and time.');
        console.log("121212121221212 worng date pike")
        event.preventDefault();
        return false;
    }
    else {
        console.log("121212121221212 right date pike")
    }
    return true;
};

export const getCurrentDate = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0');
    const day = String(now.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
};

export const getCurrentTime = () => {
    const now = new Date();
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    return `${hours}:${minutes}`;
};


export const getSerchByUserName = async (userName: any) => {
    const q = query(
        collection(db, 'users'),
        where('userName', '==', userName),
        limit(1)
    );
    const usersSnapshot = await getDocs(q);
    const existingUsernames = usersSnapshot.docs.map((doc:any) => doc.data());
    const isUsernameTaken = await existingUsernames;
    return isUsernameTaken

};

// export const getCardNameById = async (cardId: any) => {
//     const q = query(
//         collection(db, 'cardsDetails'),
//         where('cardId', '==', cardId),
//         limit(1)
//     );
//     const usersSnapshot = await getDocs(q);
//     const existingUsernames = usersSnapshot.docs.map((doc:any) => doc.data());
//     const isUsernameTaken = await existingUsernames[0]?.cardName;
//     console.log(isUsernameTaken,"isUsernameTaken")
//     return isUsernameTaken
// };

export const removeVote = (
    voteDetails: VoteContextType,
    setVoteDetails: React.Dispatch<React.SetStateAction<VoteContextType>>,
    setAfterVotePopup: any,
    setCompletedVotes: React.Dispatch<React.SetStateAction<VoteResultProps[]>>,
    setLessTimeVoteDetails: React.Dispatch<React.SetStateAction<VoteResultProps | undefined>>,
    userInfo: UserProps | undefined,
) => {
    let temp = {};
    setCompletedVotes(prev => prev.filter(value => value.voteId != voteDetails?.lessTimeVote?.voteId));
    setVoteDetails((prev) => {
        Object.keys(prev?.activeVotes).map((key: string) => {
            if (voteDetails?.lessTimeVote?.voteId !== prev?.activeVotes[key].voteId) {
                temp = { ...temp, [`${prev?.activeVotes[key].coin}_${prev?.activeVotes[key]?.timeframe?.seconds}`]: prev?.activeVotes[key] }
            }
        });
        return {
            ...prev,
            lessTimeVote: undefined,
            activeVotes: temp,
            openResultModal: false,
        };
    });
    if (Object.keys(temp)?.length <= 0 && (Number(userInfo?.voteValue || 0) + Number(userInfo?.rewardStatistics?.extraVote || 0)) <= 0) {
        setAfterVotePopup(true);
    }

    setLessTimeVoteDetails(undefined);
}
