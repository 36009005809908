/** @format */
//@ts-nocheck
import React, { useContext, useEffect, useRef, useState } from "react";
import lottie from "lottie-web";
import confetti from "../../assets/animation/confetti.json";
import "./style.css";
import TheEagle from "../../assets/images/TheEagle.png";
import styled from "styled-components";
import AppContext from "../../Contexts/AppContext";
import common from "../../assets/images/commonText.png";
import epic from "../../assets/images/epicText.png";
import legendary from "../../assets/images/legendaryText.png";
import rare from "../../assets/images/rareText.png";
import uncommon from "../../assets/images/uncommonText.png";
import Showround from "../../assets/images/Showround.png";
import information from "../../assets/svg/information.svg";
import { Buttons } from "../../Components/Atoms/Button/Button";
import { useNavigate } from "react-router-dom";
import { collection, getDocs, query, where } from "firebase/firestore";
import VideoPopup from "Pages/VideoPopup";
import UserContext from "Contexts/User";
import useSound from "use-sound";
import { firestore } from "../../firebase";
// @ts-ignore
import scratchsound from "../../assets/sounds/scratch.mp3";
// @ts-ignore
import claimSound from "../../assets/sounds/claimReward.m4a";

type MintingProps = {
  cardType?: any;
  rewardTimer?: any;
  setRewardTimer?: any;
  openpopup?: any;
  handleShareModleShow?: any;
  handleCardClose?: any;
  setCountShow?: any;
  setBefornotShow?: any;
  befornotShow?: any;
  setCardsDetails?: any;
  setAddPaxWalletPop?: any;
  setChangeBG?: any;
  approvedCard?: any;
  setShareModalShow?: any;
};

const MainDiv = styled.div`
  opacity: 1;
  z-index: 2200;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity 1s ease;
`;

const ScratchCard = styled.canvas`
  position: absolute;
  top: 0;
  z-index: 2202;
  width: 100%;
  height: 100%;
`;

function NFTTradeCard({
  rewardTimer,
  cardType = rewardTimer?.data?.firstRewardCardType || "legendary",
  setRewardTimer,
  openpopup,
  handleShareModleShow,
  handleCardClose,
  setCountShow,
  setBefornotShow,
  befornotShow,
  setCardsDetails,
  setAddPaxWalletPop,
  setChangeBG,
  approvedCard,
  setShareModalShow,
}: MintingProps) {
  const classname = `card shadow ${cardType.toLowerCase()} `;
  const [isDrawing, setisDrawing] = useState<any>(false);
  const [startX, setStartX] = useState<any>(0);
  const [startY, setStartY] = useState<any>(0);
  const [cressShow, setCressShow] = useState<any>(false);
  const [scratchShound, setScratchShound] = useState<any>(false);
  const [showImg, setShowImg] = useState<any>(true);
  const [scratchFinish, setScratchFinish] = useState<any>(false);
  const [Videoshow, setVideoshow] = useState(false);
  const [fulldata, setFulldata] = useState([]);
  const [handleSoundClickCardplay, { stop: handleSoundClickCardpause }] =
    useSound(scratchsound, { interrupt: false, loop: true });

  const { showReward, setShowReward } = useContext(AppContext);
  const [mintedTime, setMintedTime] = useState("");
  const { user } = useContext(UserContext);
  const [claimRewardSoundplay, { stop: claimRewardSoundpause }] =
    useSound(claimSound);

  const [rotateCard, setRotateCard] = useState<boolean>(false);
  const navigate = useNavigate();
  const cardDiv = useRef();
  const WIDTH = 252;
  const HEIGHT = 320;

  const cardsDetailsCollectionRef = collection(firestore, "cardsDetails");
  const rewardTransactionsCollectionRef = collection(
    firestore,
    "reward_transactions"
  );

  const fetchData = async () => {
    try {
      // Fetch card details
      const cardDetailsQuery = query(
        cardsDetailsCollectionRef,
        where("cardId", "==", rewardTimer?.data?.firstRewardCardId)
      );
      const cardDetailsSnapshot = await getDocs(cardDetailsQuery);

      const cardData = cardDetailsSnapshot.docs.map((doc) => {
        const cardDetails = doc.data();
        return {
          cardType: cardDetails?.cardType,
          setName: cardDetails?.setName,
          cardName: cardDetails?.cardName,
          albumName: cardDetails?.albumName,
          noOfCardHolders: cardDetails?.noOfCardHolders,
          cardId: cardDetails?.cardId,
          id: cardDetails?.cardId,
          totalQuantity: cardDetails?.totalQuantity,
          cardImageUrl: cardDetails?.cardImageUrl,
          cardVideoUrl: cardDetails?.cardVideoUrl,
          setId: cardDetails?.setId,
        };
      });
      // @ts-ignore
      setFulldata(cardData[0]);

      // Fetch reward transactions
      const rewardTransactionsQuery = query(
        rewardTransactionsCollectionRef,
        where("user", "==", user?.uid)
      );
      const rewardTransactionsSnapshot = await getDocs(rewardTransactionsQuery);

      rewardTransactionsSnapshot.forEach((cards) => {
        if (
          cards.data()?.winData?.firstRewardCardSerialNo ==
          rewardTimer?.data?.firstRewardCardSerialNo
        ) {
          const date = new Date(cards.data()?.transactionTime?.seconds * 1000);
          var getMintedTime = date.toLocaleString();
          setMintedTime(getMintedTime);
        }
      });
      // scratchEndMobile();
    } catch (error) {
      console.error("Error fetching data rewardTimer:", error);
    }
  };

  // Usage within a React component
  useEffect(() => {
    if (rewardTimer != null) {
      fetchData();
      claimRewardSoundplay();
      const Animation = lottie.loadAnimation({
        // @ts-ignore
        // container: document.querySelector("#card-animation"),
        container: document.querySelector("#card-animation"),
        animationData: confetti,
        renderer: "html", // "canvas", "html"
        loop: true, // boolean
        autoplay: true, // boolean
      });

      setTimeout(function () {
        Animation.pause();
        // Animation.destroy();
        claimRewardSoundpause();
      }, 9000);
    }
  }, [
    user?.uid,
    rewardTimer?.data?.firstRewardCardId,
    rewardTimer?.data?.firstRewardCardSerialNo,
  ]);

  return (
    <div
      className="d-flex justify-content-around align-items-center flex-column"
      style={{
        height: "100%",
      }}
    >
      <div
      // onClick={()=>setVideoshow(true)}
      >
        {/* click */}
      </div>
      <MainDiv>
        <div
          style={{
            position: "relative",
          }}
        >
          <div className="text-center"
            style={{
              color: `${allColor[`${cardType.toLowerCase()}`].fill}`,              
              fontSize: "25px",
              marginTop:"-10px",
            }}
          >
          <p>Congratulations</p>
          </div>
          {/* @ts-ignore */}
          <div
            className={classname}
            id=""
            style={{
              position: "relative",
              height: "335px",
            }}
          >
            <div
              id="card-animation"
              style={{
                height: "280px",
                width: "245px",
                position: "absolute",
                top: "25px",
              }}
            />

            <div className={""} className={`${!showImg ? "d-none" : ""}`}>
              <div className="d-flex justify-content-around">
                <div
                  className={""}
                  className={`${!fulldata ? "opacity-0" : ""}`}
                  style={{
                    fontSize: "12px",
                    width: "25%",
                  }}
                >
                  <img
                    className=""
                    style={{
                      padding: "0px 0px 0px 10px",
                      cursor: "pointer",
                    }}
                    width={"35px"}
                    onClick={() => {
                      setVideoshow(true);
                    }}
                    src={Showround}
                  />
                </div>
                <span
                  className={`${cardType.toLowerCase()}_text`}
                  style={{
                    width: "50%",
                  }}
                >
                  &nbsp; {cardType?.toUpperCase()} &nbsp;{" "}
                </span>

                <div
                  className={`${!fulldata ? "opacity-0" : ""} px-2`}
                  style={{
                    fontSize: "12px",
                    width: "25%",
                    textAlign: "right",
                    fontWeight: "bold",
                  }}
                >
                  <div>
                    <img
                      src={information}
                      alt=""
                      className="mt-1"
                      width={"15px"}
                      height={"15px"}
                    />
                  </div>
                </div>
              </div>
              <div
                className="d-flex justify-content-center"
                style={{
                  paddingTop: "1em",
                  position: "relative",
                }}
              >
                <div
                  className=""
                  style={{
                    position: "absolute",
                    left: "-50px",
                    bottom: "60px",
                    transform: "rotate(-90deg)",
                    width: "125px",
                    color: "black",
                  }}
                >
                  <p>{rewardTimer?.data?.firstRewardCardSerialNo || ""}</p>
                </div>
                <img
                  src={rewardTimer?.data?.firstRewardCardImageUrl || TheEagle}
                  alt="the hgodler"
                  style={{
                    width: "245px",
                    margin: "auto",
                    display: "block",
                    marginTop: "-7px",
                    paddingBottom: "0.8em",
                  }}
                />
                <span
                  style={{
                    position: "absolute",
                    bottom: "-14PX",
                    color: "black",
                    paddingTop: "0.5em",
                  }}
                  className="cardname"
                >
                  <strong>
                    {rewardTimer?.data?.firstRewardCard || "HODLER"}
                  </strong>
                </span>
              </div>
            </div>
          </div>
          {/* @ts-ignore */}
          {/* {!cressShow && (
            <ScratchCard
              ref={cardDiv}
              onMouseDown={(e) => {
                e.stopPropagation();
                if (window.screen.width < 768) return;
                scratchStart(e);
              }}
              onMouseUp={(e) => {
                e.stopPropagation();
                if (window.screen.width < 768) return;
                scratchEnd(e);
              }}
              onMouseMove={(e) => {
                e.stopPropagation();
                if (window.screen.width < 768) return;
                scratch(e);
              }}
              onTouchStart={(e) => {
                if (window.screen.width > 768) return;
                scratchStartMobile(e); // Use the first touch point
              }}
              onTouchEnd={(e) => {
                if (window.screen.width > 768) return;
                scratchEndMobile(); // Use the first touch point
              }}
              onTouchMove={(e) => {
                if (window.screen.width > 768) return;
                scratchMobile(e); // Use the first touch point
              }}
              width={`${WIDTH}px`}
              height={`${HEIGHT}px`}
              id="canvas"
            ></ScratchCard>
          )} */}
        </div>
      </MainDiv>
      <div
        // className={`${
        //   !cressShow ? "opacity-0" : ""
        // } w-100 d-flex justify-content-center `}
        className={`${""} w-100 d-flex justify-content-center `}
      >
        <Buttons.Primary
          className="mx-2"
          onClick={() => {
            // setRewardTimer(null);
            // setShowReward(0);
            // handleShareModleShow();
            handleCardClose();
            setShareModalShow(true);
            // setCountShow(false);
            // claimRewardSoundpause();
            // setCardsDetails(rewardTimer?.data);
            // setChangeBG(0);
          }}
          style={{
            backgroundColor: `${allColor[`${cardType.toLowerCase()}`].fill}`,
            width: `${"120px"}`,
          }}
        >
          {/* Share Card */}
          BARG & WIN
        </Buttons.Primary>

        <Buttons.Primary
          className="mx-2"
          onClick={() => {
            // setRewardTimer(null);
            // setShowReward(0);
            // setCountShow(false);
            navigate(
              `/profile/CollectiblesAlbum?collectionName=${rewardTimer?.data?.firstRewardCardCollection?.replace(
                " ",
                "-"
              )}
              `
            );
            // &cardName=${rewardTimer?.data?.firstRewardCard?.replace(
            //   " ",
            //   "-"
            // )}
            // claimRewardSoundpause();
            // setChangeBG(0);
          }}
          style={{
            backgroundColor: `${allColor[`${cardType.toLowerCase()}`].fill}`,
            width: `${"120px"}`,
          }}
        >
          YOUR COLLECTION {/* Check Win Card */}
        </Buttons.Primary>
      </div>
      {Videoshow && (
        <VideoPopup
          fulldata={fulldata}
          setVideoshow={setVideoshow}
          Videoshow={Videoshow}
          // @ts-ignore
          videoUrl={fulldata?.cardVideoUrl}
          // @ts-ignore
          imgUrl={fulldata?.cardImageUrl}
          MintedTime={mintedTime}
          PrivateSerialNo={rewardTimer.data.firstRewardCardSerialNo}
        />
      )}
    </div>
  );
}

export default NFTTradeCard;

export const allColor: { [key: string]: { [key: string]: any } } = {
  epic: {
    color: "white",
    background: "#4938CD",
    backgroundimg: epic,
    fill: "#4938CD",
  },
  common: {
    color: "white",
    background: "#C8C0F3",
    backgroundimg: common,
    fill: "#4938CD",
  },
  rare: {
    color: "#292929",
    background: "#9D9D9D",
    fill: "#7E7E7E",
    backgroundimg: rare,
  },
  uncommon: {
    color: "#6438C1",
    background: "#A27CF9",
    fill: "#6438C1",
    backgroundimg: uncommon,
  },
  legendary: {
    color: "#292929",
    background: "#DC9F26",
    fill: "#A89120",
    backgroundimg: legendary,
  },
};
