import React from "react";
import GeneralPage from "../GeneralPage";
import { Accordion, Container } from "react-bootstrap";
import { gameRules } from "../common/consts/contents";
import styled from "styled-components";

const Page = styled.div`
  font-size: 13px;
  margin:auto;
  line-height: 21px;  
  background: transperent;
  color: #160133;
  font-weight:100;
  min-height:82vh;
  & h1 {
    color: #6352E8;
    font-size: 18px;
    line-height: 21px;
    margin-bottom: 20px;
  }

  & a {
    color: #6352E8;
  }

  & p {
    margin-bottom: 20px;
  }
`;
const Header = styled(Accordion.Header)`
  & .accordion-button {    
    color: #6352e8;
    // background-color: #fff;
     box-shadow: none;
    border-color: rgba(0,0,0,.125);
    z-index:0;
    &::after {
      margin-right: 0;
    }
  }
`;
const Body = styled(Accordion.Body)`

  color: #212529;
`;
const GameRule = () => {
  return (
    <div className="gr_bg">
      <Page className="content-pages"
        style={{
          padding: `${window.screen.width > 767 ? "24px 47px" :"24px 20px"}`
      }}
      >
        <div style={{ margin: 'auto', maxWidth: '1000px' }}>
          {/* <h1 style={{ textAlign: 'center' }} className="page-title">Coin Parliament Game Rules</h1> */}
          <br />
          <h2 className="sub-title mb-2">Game Goal</h2>
          <p>The aim of Coin Parliament is to collect unique cards and convert them into valuable Collectibles.</p>
          <br />
          <h2 className="sub-title mb-2">How to Achieve the Goal</h2>
          <p>Start by becoming a Member and participating in votes for your favorite crypto coins and pairs. Each vote has three impact levels—Low, Mid, and High—which influence the game in different ways. By voting regularly, you accumulate Coin Mining Progress (CMP), tracking your advancement. Reaching 100 CMP unlocks rewards, including Parliament coins (VTE), extra votes, and unique cards. You progress through influencer levels—Chairman, Minister, Ambassador, Counsel, and Speaker—which affect your CMP rewards and can change based on your voting activity and seniority. Collect unique cards as you vote, and upgrade to a Mining account to convert these cards into Collectibles. With an upgraded Mining account, your Collectibles are unique, permanent items recorded on the public blockchain and are 100% owned by you.</p>
          <br />
          <h2 className="sub-title mb-2">Game Flow and Levels</h2>
          <p>The game starts with you as a Member. As you vote and accumulate CMP, you unlock rewards and advance through influencer levels. Collecting unique cards is a primary focus. Reaching 100 CMP unlocks rewards and upgrading to a Mining account allows for automatic conversion of cards into blockchain-recorded Collectibles. Higher influencer levels provide better CMP rewards and additional opportunities.</p>
          <br />
          <h2 className="sub-title mb-2">Why Should You Play?</h2>
          <p>Coin Parliament offers a rewarding and unique experience by allowing you to collect and convert cards into permanent blockchain Collectibles. Enjoy benefits such as Parliament coins (VTE), extra votes, and discounts on Vote To Earn merchandise. Participate in the Ambassador Program to refer friends and earn commissions from their purchases, enhancing your income potential. Coin Parliament combines fun, strategy, and the chance to thrive in the crypto ecosystem, making every vote count and providing numerous opportunities for success.</p>

          <Accordion defaultActiveKey={["0"]} alwaysOpen className="d-none">
            {gameRules.map((value, i) => {
              return (
                <Accordion.Item eventKey={i.toString()} key={i}>
                  <Header>
                    <Container>{i + 1}. {value.step}</Container>
                  </Header>
                  <Body>
                    <Container>
                      <div style={{ textAlign: 'justify' }} dangerouslySetInnerHTML={{ __html: (value.desc || '') }} />
                    </Container>
                  </Body>
                </Accordion.Item>
              );
            })}
          </Accordion>

          <div className="pt-3 d-none" style={{ textAlign: 'justify' }}>
            <h1>Game Flow and Levels:</h1>
            <p>The game starts with you as a Member.
              You progress by voting, accumulating CMP, and reaching influencer levels.
              Collecting unique cards is a primary focus.
              When you reach 100 CMP, you unlock rewards.
              Upgrading to a Mining account enables automatic card-to-Collectible conversion.
              Higher influencer levels grant better CMP rewards.
              Why Should You Do It and Benefits:</p>

            <p>
              Collecting cards and converting them into Collectibles is rewarding and unique.
              Collectibles are permanently recorded on the blockchain.
              Members can showcase, trade, or sell their Collectibles.
              Being the first owner of a Collectible card earns you lifetime royalties of 50% from its sales, providing a continuous income stream.
              Additionally, you can earn Parliament coins (VTE), extra votes, and discounts on Vote To Earn merchandise.
              The Ambassador Program allows you to refer friends and earn commissions from their purchases, adding to your income potential.
              In summary, Coin Parliament: Vote To Earn is a game where you progress by voting, collecting cards, upgrading to Mining for Collectibles, and enjoying the benefits within the crypto ecosystem, including lifetime royalties from the sale of your Collectible cards.
            </p>
          </div>

        </div>
      </Page>
    </div>
  );
};

export default GameRule;
