
import UserContext from 'Contexts/User';
import axios from 'axios'
import { BearVsBullRow } from 'common/models/CoinTable';
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { Image, Modal } from 'react-bootstrap';
import PAX from '../../../assets/logos/PAX.png';
import VTE from '../../../assets/logos/VTE.png';
import AppContext from 'Contexts/AppContext';
import { useNavigate } from 'react-router-dom';
import { httpsCallable } from 'firebase/functions';
import { functions } from '../../../firebase';
import styled from 'styled-components';

export const MemberText = styled.span`
  margin-top: 10px;
    text-transform: uppercase;
    padding: 10px 20px;
    background: rgb(212, 208, 243);
    color: rgb(99, 82, 232);
    border-radius: 5px;
    font-size: 10px;
`;

const Logo = ({ symbol }: BearVsBullRow) => {
  return (
    <Image
      src={process.env.PUBLIC_URL + `/images/logos/${symbol?.toUpperCase()}.svg`}
      style={{
        // margin: "0 auto",
        width: `${symbol !== "ETH" ? "40px" : "35px"}`,
        height: `${symbol !== "ETH" ? "40px" : "40px"}`,
      }}
      onError={(e) =>
        ((e.target as HTMLImageElement).src = "/images/no_logo.png")
      }
    />
  );
};

const WalletRwceive = (selectTabCheck:any) => {
  const [pendingAmount, setPendingAmount] = useState({
    ETH: 0,
    BNB: 0,
    MATIC: 0,
    USDT: 0,
    TON: 0,
  })

  let navigate = useNavigate();
  const { walletTab, setWalletTab } = useContext(AppContext);
  const { userInfo } = useContext(UserContext);
  const [modalShow, setModalShow] = React.useState(false);
  const handleClose = () => setModalShow(false);
  const [completedPAX, setCompletePax] = useState(0);
  const getCompletedPaxCountOfUserData = httpsCallable(functions, "getCompletedPaxCountOfUser");  
  const getCompletedPaxCountOfUser = async () => {
    if (userInfo?.isUserUpgraded) {
      const data= {
        userId: userInfo?.uid
      }              
      await getCompletedPaxCountOfUserData(data).then((res) => {
        
        // @ts-ignore
          setCompletePax(res?.data?.result || 0);
        
        }).catch((err) => {
          console.log(err)
        });      
    }
  };

  useEffect(() => {
    if (selectTabCheck?.selectTabCheck == "Receive"){getCompletedPaxCountOfUser()}  
  }, [userInfo?.uid, userInfo?.isUserUpgraded, userInfo?.paxAddress?.address, selectTabCheck?.selectTabCheck]);
  

  return (
    <>
   {!userInfo?.isUserUpgraded ? 
   <div 
      style={{
            display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        fontSize: "20px",
        fontWeight: "600",
        alignItems:"center",
        textTransform:"uppercase",
        background:"white",
        maxWidth: "600px",
        padding: "20px",
        borderRadius: "15px",
        margin: "0px auto",
        textAlign: "center",
          }}>
          <>
            <p>Buy your Mining Voting Machine and get your rewards</p>
            <div className='pt-3'>
            {!!userInfo?.status?.name &&              
              !userInfo?.isUserUpgraded && (
                <MemberText
                  style={{
                    background: "#ff9700",
                    color: "black",
                  }}
                  onClick={() => {                                        
                      navigate("/upgrade");                  
                  }}
                >
                  Buy your MVM
                </MemberText>
              )}
            </div>
          </>
    </div>
    :
        <div className='d-flex justify-content-center align-items-center'
          style={{
            color:"white"
          }}
    >
      <div className='mt-3'>
        <p className='mx-3' style={{
          fontSize: "18px"
            }}>{"YOUR REWARD RECEIVE AMOUNT".toLocaleUpperCase()}</p>
        <div className='d-flex justify-content-around align-items-center my-2'
          style={{
            background: "white",
            borderRadius: "5px",
            padding: "8px 8px",
          }}
        >
          <div className='d-flex align-items-center'>
            <img src={PAX} alt=""
              style={{
                // margin: "0 auto",
                width: "40px",
                height: "40px",
              }}
            />
            <p className=''
              style={{
                marginLeft: `${"23px"}`,
                color: "black",
              }}
            >PAX</p>
          </div>
          <p className=''
            style={{
              color: "#6352E8",
            }}
          >{completedPAX}</p>
            </div>
            <div className='d-flex justify-content-around align-items-center my-2'
              style={{
                background: "white",
                borderRadius: "5px",
                padding: "8px 8px",
              }}
            >
              <div className='d-flex align-items-center'>
                <img src={VTE} alt=""
                  style={{
                    // margin: "0 auto",
                    width: "40px",
                    height: "40px",
                  }}
                />
                <p className=''
                  style={{
                    marginLeft: `${"23px"}`,
                    color: "black",
                  }}
                >VTE</p>
              </div>
              <p className=''
                style={{
                  color: "#6352E8",
                }}
              // @ts-ignore
              >{userInfo?.rewardStatistics?.rewardedVTE || 0}</p>
            </div>
        <p className='mx-3' style={{
          fontSize: "18px",
          margin: "20px 0px 10px 0px"
        }}>{"Your Referral Receive Amount".toLocaleUpperCase()}</p>

        {Object?.keys(pendingAmount)?.length ?
          <div className='d-flex flex-column'>
            {Object?.keys(pendingAmount)?.map((value, index) => {
              if (value == "ETH" || value == "BNB" || value == "MATIC" || value == "USDT"|| value == "TON") {
                return <>

                  <div className='d-flex justify-content-around align-items-center my-2 '
                    style={{
                      background: "white",
                      borderRadius: "5px",
                      padding: "8px",
                    }}
                  >
                    <div className='d-flex align-items-center'>
                      <Logo
                        symbol={value}

                      />
                      <p className=''
                        style={{
                          marginLeft: `${value == "ETH" ? "23px" : "20px"}`,
                          color:"black",
                        }}
                      >{value == "MATIC" ? "POL" : value} </p>
                    </div>

                    {/* @ts-ignore */}
                    <p className=''
                      style={{
                        color: "#6352E8",
                      }}
                    >{pendingAmount[value] != 0 ? Number(pendingAmount[value])?.toFixed(4) : 0}</p>
                  </div>
                </>
              }

            })}                
          </div>
          :
          <div className="d-flex justify-content-center" >
            <p className='mt-3'>Pending Payment Amount is not found</p>
          </div>
        }

      </div>

      <div className="pb-3">
      </div>
      <div>
        <Modal
          show={
            modalShow
          } onHide={handleClose}
          // size="sm"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <div className="d-flex justify-content-end">
            <button type="button" className="btn-close " aria-label="Close" onClick={() => {
              handleClose()
            }}></button>
          </div>
        </Modal>
      </div >
    </div>}
    </>
  )
}

export default WalletRwceive